

export default {
  checkPermission(permission) {
    let userPermissions = this.$store.getters["authentication/getPermissions"];
    if (
      userPermissions.find(item => item === "all_permissions") ===
      "all_permissions"
    )
      return true;
    return userPermissions.find(item => item === permission) === permission;
  }
};
