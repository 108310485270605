export default[
  {
    path: 'editors',
    redirect: '/editors/text-editors',
    name: 'Editors',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'text-editors',
        name: 'Text Editors',
        component: () => import('./../../../views/editors/TextEditors')
      },
      {
        path: 'code-editors',
        name: 'Code Editors',
        component: () => import('./../../../views/editors/CodeEditors')
      }
    ]
  },
]
