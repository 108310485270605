export default[
  {
    path: 'email',
    meta: { label: 'Emails' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        component: () => import('./../../../views/email/Emails'),
      },
      {
        path: 'create',
        meta: { label: 'Create Email Template' },
        name: 'Create Email Template',
        component: () => import('./../../../views/email/CreateEmail')
      },
      {
        path: ':id',
        meta: { label: 'Show Email Template' },
        name: 'Show Email Tempalte',
        component: () => import('./../../../views/email/ShowEmail'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Email Tempalate' },
        name: 'Edit Email Template',
        component: () => import('./../../../views/email/EditEmail')
      },
      {
        path: ':id/sendEmail',
        meta: { label: 'Send Email' },
        name: 'Send Email',
        component: () => import('./../../../views/email/SendEmail')
      },
    ]
  },
]
