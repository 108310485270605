<script>
import MapElementMixin from '../mixins/map-element';
import {getPropsValues, bindEvents, bindProps} from '../utils/helpers';
import {advancedPinMappedProps} from '../utils/mapped-props-by-map-element'

export default {
  name: "AdvancedPinIcon",
  mixins: [MapElementMixin],
  inject: {
    $advMarkerPromise: {
      default: null,
    }
  },
  provide() {
    const events = [
      'click',
      'dblclick',
      'drag',
      'dragend',
      'dragstart',
      'mousedown',
      'mousemove',
      'mouseout',
      'mouseover',
      'mouseup',
      'rightclick',
    ];
    const promise = this.$mapPromise
      .then(async (map) => {
        this.$map = map;

        // Initialize the maps with the given options
        const initialOptions = {
          ...this.options,
          ...getPropsValues(this, advancedPinMappedProps),
        };

        const {options: extraOptions, ...finalOptions} = initialOptions;

        const {PinElement} = await google.maps.importLibrary("marker")

        this.$advPinObject = new PinElement(finalOptions);

        bindProps(this, this.$advPinObject, advancedPinMappedProps);
        bindEvents(this, this.$advPinObject, events);

        if (this.$advMarkerPromise) {
          this.$advMarkerPromise.then((advMarkerObject) => {
            advMarkerObject.content = this.$advPinObject.element;
            this.$advMarkerObject = advMarkerObject
          })
        }

        return this.$advPinObject;
      })
      .catch((error) => {
        throw error;
      });

    // TODO: analyze the efects of only returns the instance and remove completely the promise
    this.$advPinPromise = promise;
    return {$advPinPromise: promise};
  },
  props: {
    background: {
      type: String,
      default: "#EA4335"
    },
    borderColor: {
      type: String,
      default: "#b31412"
    },
    glyphColor: {
      type: String,
      default: "#b31412"
      //   #d7372f
    },
    scale: {
      type: Number,
      default: 1
    },
  },
  watch: {
    background() {
      this.$advMarkerObject.content = this.$advPinObject.element;
    }, borderColor() {
      this.$advMarkerObject.content = this.$advPinObject.element;
    },
    glyphColor() {
      this.$advMarkerObject.content = this.$advPinObject.element;
    }, scale() {
      this.$advMarkerObject.content = this.$advPinObject.element;
    }
  },
  destroyed() {
    if (this.$advPinObject) {
      this.$advPinObject = null;
    }
  },
  render() {
    return '';
  },
}
</script>

