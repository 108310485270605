export default[
  {
    path: 'email',
    redirect: '/apps/email/inbox',
    name: 'Email',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'compose',
        name: 'Compose',
        component: () => import('./../../../views/apps/email/Compose')
      },
      {
        path: 'inbox',
        name: 'Inbox',
        component: () => import('./../../../views/apps/email/Inbox')
      },
      {
        path: 'message',
        name: 'Message',
        component: () => import('./../../../views/apps/email/Message')
      }
    ]
  },
]
