export default [{
  path: 'dashboards',
  name: 'Dashboards',
  redirects: "dashboards/main",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "main",
      name: "Main Dashboard",
      meta: {
        title: 'Home',
        permission: 'show_menu_main'
      },
      component: () =>
        import ('../../../views/dashboards/main.vue'),
    },
    {
      path: "kpis",
      name: "KPIS Dashboard",
      meta: {permission: "show_menu_kpis"},
      component: () => import("../../../views/dashboards/kpis/index.vue")
    }, {
      path: "sales",
      name: "Sales Dashboard",
      meta: {permission: "show_menu_link_sales"},
      component: () => import("../../../views/dashboards/sales/index.vue")
    }, {
      path: "maps",
      name: "Maps Dashboard",
      meta: {permission: "show_menu_maps"},
      component: () => import("../../../views/dashboards/maps/index.vue")
    }, {
      path: "calls",
      name: "Calls Dashboard",
      meta: {permission: "show_menu_calls"},
      component: () => import("../../../views/dashboards/calls/index.vue")
    },
    {
      path: "statistics",
      name: "Statistics Dashboard",
      meta: {permission: "show_menu_statistics"},
      component: () => import("../../../views/dashboards/statistics/index.vue")
    },
    {
      path: "test",
      name: "test Dashboard",
      meta: {permission: "show_all_coaching"},
      component: () => import("../../../views/test_spreadsheet/index.vue")
    },
    {
      path: "m.r-statistics",
      name: "M.R Statistics Dashboard",
      meta: {permission: "show_menu_mr_statistics"},
      component: () => import("../../../views/dashboards/visit-statistics/index.vue")
    },
    {
      path: "account-types-statistics",
      name: "Account Type Dashboard",
      meta: {permission: "show_menu_account-types-statistics"},
      component: () => import("../../../views/dashboards/account-types-statistics/index.vue")
    },
  ]
}]
