import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle(state, variable) {
    state[variable] = !state[variable];
  }
};

const actions = {
  initializeStore({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      if (
        localStorage.getItem("api_token") &&
        localStorage.getItem("AuthUser")
      ) {
        commit("app/setApiToken", localStorage.getItem("api_token"));
        commit(
          "authentication/setAuthUser",
          JSON.parse(localStorage.getItem("AuthUser"))
        );
        commit("authentication/setImpersonated", !!localStorage.getItem("old_api_token"));
        dispatch("authentication/loadPermissions");
        commit(
          "authentication/setAccountLock",
          localStorage.getItem("AccountLocked") === "true"
        );
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
};

async function loadModules() {
  const modules = {};
  const requireComponents = import.meta.glob('./**/index.js');

  for (const fileName in requireComponents) {
    const module = await requireComponents[fileName]();
    const parts = fileName.split('/');
    const componentName = parts[parts.length - 2].split('-')
      .map((part, index) => index === 0 ? part : part[0].toUpperCase() + part.slice(1))
      .join('');
    modules[componentName] = module.default || module;
  }
  return modules
}



async function createStore(){
  const modules = await loadModules();
  return new Vuex.Store({
    modules,
    state,
    mutations,
    actions
  });
}

export const storePromise = createStore();
