export default[
  {
    path: 'notes',
    meta: { label: 'Notes' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        component: () => import('./../../../views/notes/Notes'),
      },
      {
        path: 'create',
        meta: { label: 'Create Note' },
        name: 'CreateNote',
        component: () => import('./../../../views/notes/CreateNote')
      },
      {
        path: ':id',
        meta: { label: 'Note Details' },
        name: 'Note',
        component: () => import('./../../../views/notes/Note'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Note' },
        name: 'EditNote',
        component: () => import('./../../../views/notes/EditNote')
      },
    ]
  },
]
