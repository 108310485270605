export default [
  {
    path: '/approval-settings',
    meta: { label: 'Approval Setting' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [{
      path: '',
      name: 'approval-general-settings',
      meta: {
        permission: 'show_all_approval_settings'
      },
      component: () =>
        import('../../views/approvalgeneralsettings/index.vue'),
    },
    {
      path: ':id/edit',
      meta: {
        label: 'Edit Dashboard Widget Settings',
        permission: 'edit_approval_settings'
      },
      name: 'EditApprovalSettings',
      component: () =>
        import('../../views/approvalgeneralsettings/edit.vue'),
    },
    ]
  },
]
