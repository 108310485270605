export default [{
    path: '/calendar',
    redirect: '/pages/404',
    name: 'Calendar',
    component: {
        render(c) { return c('router-view') }
    },
    children: [
    {
        path: '/',
        name: 'Calendar',
        meta: { permission:'show_single_calendar' },
        component: () =>
            import ('../../../views/Calendar.vue')
    },
  ]
}]
