export default [
  {
    path: "/internal-messaging",
    meta: { label: "Internal Messaging" },
    redirect: { name: "All_Messages" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "inbox",
        name: "All_Messages",
        component: () => import("../../views/internal-messaging/inbox.vue")
      },
      {
        path: "trash/messages",
        name: "Trash",
        component: () => import("../../views/internal-messaging/trash.vue")
      },
      {
        path: "sent/messages",
        name: "Sent",
        component: () => import("../../views/internal-messaging/sent.vue")
      },
      {
        path: "important/messages",
        name: "Important",
        component: () => import("../../views/internal-messaging/important.vue")
      },
      {
        path: "saved/messages",
        name: "Saved",
        component: () => import("../../views/internal-messaging/saved.vue")
      },
      {
        path: "compose",
        name: "New_Message",
        component: () => import("../../views/internal-messaging/compose.vue")
      },
      {
        path: "messages/:id",
        name: "Show_Message",
        component: () => import("../../views/internal-messaging/message.vue")
      }
    ]
  }
];
