export default [{
    path: '/help',
    meta: { label: 'Help' },
    component: {
        render(c) { return c('router-view') }
    },
    children: [{
            path: '',
            name: 'Topics',
            meta: { permission:'access_button_help_data'},
            component: () =>
                import ('../../../views/help/Index')

        },
        {
            path: 'articles/:id',
            name: 'ShowArticle',
            meta: { permission:'show_single_articles'},
            component: () =>
                import ('../../../views/help/Show')
        }
    ]
}]