export default [
  {
    path: '/positions',
    meta: { label: 'positions' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'positions-details-settings',
        meta: {
          permission: 'show_all_positions'
        },
        component: () => import('./../../views/positions/index.vue'),
      },
      {
        path: 'create-position',
        name: 'create-position',
        meta: {
          permission: 'create_position'
        },
        component: () => import('./../../views/positions/create.vue'),
      },
      {
        path: 'edit-position/:id',
        name: 'edit-position',
        meta: {
          permission: 'edit_position'
        },
        component: () => import('./../../views/positions/edit.vue'),
      },
    ]
  }
]
