<script>
import MapElementMixin from '../mixins/map-element';
import {getPropsValues, bindEvents, bindProps} from '../utils/helpers';
import {advancedMarkerMappedProps} from '../utils/mapped-props-by-map-element';

/**
 * Marker component
 * @displayName Marker
 * @see [source code](/guide/marker.html#source-code)
 * @see [Official documentation](https://developers.google.com/maps/documentation/javascript/markers)
 * @see [Official reference](https://developers.google.com/maps/documentation/javascript/reference/marker)
 */
export default {
  name: 'AdvancedMarkerIcon',
  mixins: [MapElementMixin],
  inject: {
    $clusterPromise: {
      default: null,
    },
  },
  provide() {
    const events = [
      'click',
      'rightclick',
      'dblclick',
      'drag',
      'dragstart',
      'dragend',
      'mouseup',
      'mousedown',
      'mouseover',
      'mouseout',
    ];

    const promise = this.$mapPromise
      .then(async (map) => {
        this.$map = map;

        // Initialize the maps with the given options
        const initialOptions = {
          // TODO: analyze the below line because I think it can be removed
          ...this.options,
          map,
          ...getPropsValues(this, advancedMarkerMappedProps),
        };

        const {options: extraOptions, ...finalOptions} = initialOptions;

        if (this.$clusterPromise) {
          finalOptions.map = null;
        }

        const {AdvancedMarkerElement} = await google.maps.importLibrary("marker");

        this.$advMarkerObject = new AdvancedMarkerElement(finalOptions)

        bindProps(this, this.$advMarkerObject, advancedMarkerMappedProps);
        bindEvents(this, this.$advMarkerObject, events);

        this.$advMarkerObject.addListener('dragend', () => {
          const newPosition = this.$advMarkerObject.getPosition();
          /**
           * An event to detect when a position changes
           * @property {Object} position Object with lat and lng values, eg: { lat: 10.0, lng: 10.0 }
           */
          this.$emit('update:position', {
            lat: newPosition.lat(),
            lng: newPosition.lng(),
          });
        });

        if (this.$clusterPromise) {
          this.$clusterPromise.then((clusterObject) => {
            clusterObject.addMarker(this.$advMarkerObject);
            this.$clusterObject = clusterObject;
          });
        }

        return this.$advMarkerObject;
      })
      .catch((error) => {
        throw error;
      });

    this.$advMarkerPromise = promise;
    return {$advMarkerPromise: promise};
  },
  props: {
    /**
     * Marker position. The position is required to display the marker and can be provided with Marker.setPosition if not provided at marker construction.
     * @see https://developers.google.com/maps/documentation/javascript/reference/marker
     */
    position: {
      type: Object,
      default: undefined,
    },
    /**
     * Rollover text. If provided, an accessibility text (e.g. for use with screen readers) will be added to the marker with the provided value. Please note that the title is currently only used for accessibility text for non-optimized markers.
     * @see https://developers.google.com/maps/documentation/javascript/reference/marker
     */
    title: {
      type: String,
      default: undefined,
    },
    /**
     * All markers are displayed on the map in order of their zIndex, with higher values displaying in front of markers with lower values. By default, markers are displayed according to their vertical position on screen, with lower markers appearing in front of markers further up the screen.
     * @see https://developers.google.com/maps/documentation/javascript/reference/marker
     */
    zIndex: {
      type: Number,
      default: undefined,
    },
    content: {
      type: HTMLDivElement,
      default: undefined
    }
  },
  destroyed() {
    if (!this.$advMarkerObject) {
      return;
    }

    if (this.$clusterObject) {
      // Repaint will be performed in `updated()` of cluster
      this.$clusterObject.removeMarker(this.$advMarkerObject, true);
    } else if (this.$advMarkerObject && this.$advMarkerObject.map) {
      this.$advMarkerObject.setMap(null);
    }
  },
  render(h) {
    if (!this.$slots.default || this.$slots.default.length === 0) {
      return '';
    }
    if (this.$slots.default.length === 1) {
      // So that infowindows can have a marker parent
      return this.$slots.default[0];
    }

    /**
     * @slot Default slot of the component.
     */
    return h('div', this.$slots.default);
  },
};
</script>
