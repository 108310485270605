export default [
  {
    path: 'plugins',
    redirect: '/plugins/draggable',
    name: 'Plugins',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'draggable',
        name: 'Draggable Cards',
        component: () => import('./../../../views/plugins/Draggable')
      },
      // {
      //   path: 'calendar',
      //   name: 'Calendar',
      //   component: Calendar
      // },
      {
        path: 'spinners',
        name: 'Spinners',
        component: () => import('./../../../views/plugins/Spinners')
      }
    ]
  },
  {
    path: 'apps',
    name: 'Apps',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'invoicing',
        redirect: '/apps/invoicing/invoice',
        name: 'Invoicing',
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: 'invoice',
            name: 'Invoice',
            component: () => import('./../../../views/apps/invoicing/Invoice')
          }
        ]
      },
    ]
  },
]
