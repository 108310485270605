export default {
    /**
     *
     * @param {string} path
     * @param {number} id
     */
    delete(path, id) {
        if (!path.endsWith('/')) {
            path = path.concat('/');
        }
        axios
            .post(path + id, { _method: 'DELETE' })
            .then((response) => {
                this.dangerModal = false;
                this.message = this.successfully_deleted;
                this.showAlert();
                this.getData();
            })
            .catch((error) => {
                this.showErrorMessage(error)
            });
    }
}