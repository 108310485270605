export default[
  {
    path: '/distributors',
    meta: { label: 'Distributors Details' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'distributors',
        meta: {permission: 'show_all_distributors'},
        component: () => import('./../../../views/distributors/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Distributor Create',permission: 'create_distributors' },
        name: 'CreateDistributor',
        component: () => import('./../../../views/distributors/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Distributor Details',permission: 'show_single_distributors' },
        name: 'Distributor',
        component: () => import('./../../../views/distributors/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Distributor',permission: 'edit_distributors' },
        name: 'EditDistributor',
        component: () => import('./../../../views/distributors/edit.vue')
      },
    ]
  },
]
