export default [{
        path: 'widgets',
        meta: { label: 'Widgets' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                component: () =>
                    import ('../../../views/dashboardwidgets/Widgets.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Widget Create' },
                name: 'CreateWidget',
                component: () =>
                    import ('../../../views/dashboardwidgets/CreateWidget.vue'),
            },
            {
                path: ':id',
                meta: { label: 'Widget Details' },
                name: 'Widget',
                component: () =>
                    import ('../../../views/dashboardwidgets/Widget.vue'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Widget' },
                name: 'EditWidget',
                component: () =>
                    import ('../../../views/dashboardwidgets/EditWidget.vue')
            },
    ]
}]
