import live from './live'

export default [
    {
        path: 'tasks',
        meta: { label: 'Tasks' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name:'Tasks',
                meta: { permission: 'show_all_tasks' },
                component: () => import('./../../views/tasks/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Task Create',permission:'create_tasks' },
                name: 'CreateTask',
                component: () => import('./../../views/tasks/create.vue'),
              },
              {
                path: ':id',
                meta: { label: 'Task Details',permission:'show_single_tasks' },
                name: 'Task',
                component: () => import('./../../views/tasks/show.vue'),
              },
        ]
    },
    {
        path: 'announcements',
        meta: { label: 'Announcements' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name:'Announcements',
                meta: { permission: 'show_all_announcements' },
                component: () => import('./../../views/announcements/index.vue'),
            },
            {
                path: 'create',
                meta: { label: 'Announcement Create',permission:'create_announcements' },
                name: 'CreateAnnouncement',
                component: () => import('./../../views/announcements/create.vue'),
              },
              {
                path: ':id',
                meta: { label: 'Announcement Details',permission:'show_single_announcements' },
                name: 'Announcement',
                component: () => import('./../../views/announcements/show.vue'),
              },
              {
                path: ':id/edit',
                meta: { label: 'Edit Announcement',permission:'edit_announcements' },
                name: 'EditAnnouncement',
                component: () => import('./../../views/announcements/edit.vue')
              },
        ]
    },
    ...live
]
