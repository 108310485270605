export default [
    {
        path: '/users',
        meta: { label: 'Users' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'users',
            meta: {
                permission: 'show_all_users'
            },
            component: () =>
                import('./../../views/users/index'),
        },
        {
            path: 'create',
            meta: {
                label: 'User Create',
                permission: 'create_users'
            },
            name: 'CreateUser',
            component: () =>
                import('./../../views/users/create'),
        },
        {
            path: ':id',
            meta: {
                label: 'User Details',
                permission: 'show_single_users'
            },
            name: 'User',
            component: () =>
                import('./../../views/users/show'),
        },
        {
            path: ':id/edit',
            meta: {
                label: 'Edit User',
                permission: 'edit_users'
            },
            name: 'EditUser',
            component: () =>
                import('./../../views/users/edit')
        },
        ]
    },
    {
        path: '/roles',
        meta: { label: 'Roles' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'roles',
            meta: {
                permission: 'show_all_roles'
            },
            component: () =>
                import('./../../views/roles/index.vue'),
        },
        {
            path: 'create',
            meta: {
                label: 'Create Role',
                permission: 'create_roles'
            },
            name: 'CreateRole',
            component: () =>
                import('./../../views/roles/create.vue')
        },
        {
            path: ':id',
            meta: {
                label: 'Role Details',
                permission: 'show_single_roles'
            },
            name: 'Role',
            component: () =>
                import('./../../views/roles/show.vue'),
        },
        {
            path: ':id/edit',
            meta: {
                label: 'Edit Role',
                permission: 'edit_roles'
            },
            name: 'EditRole',
            component: () =>
                import('./../../views/roles/edit.vue')
        },
        {
            path: ':id/assign-modules',
            meta: {
                label: 'Assign Role Modules',
                permission: 'assign_role_permissions'
            },
            name: 'Assign Role Modules',
            component: () =>
                import('./../../views/roles/AssignRoleModules')
        },
        ]
    },
    {
        path: '/permissions',
        meta: { label: 'Permissions' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            name: 'permissions',
            meta: {
                permission: 'show_all_permissions'
            },
            component: () =>
                import('./../../views/permissions/Permissions'),
        },
        {
            path: 'create',
            meta: {
                label: 'Create Permission',
                permission: 'create_permissions'
            },
            name: 'CreatePermission',
            component: () =>
                import('./../../views/permissions/CreatePermission')
        },
        {
            path: ':id',
            meta: {
                label: 'Permission Details',
                permission: 'show_single_permissions'
            },
            name: 'Permission',
            component: () =>
                import('./../../views/permissions/Permission'),
        },
        {
            path: ':id/edit',
            meta: {
                label: 'Edit Permission',
                permission: 'edit_permissions'
            },
            name: 'EditPermission',
            component: () =>
                import('./../../views/permissions/EditPermission')
        },
        ]
    },

];