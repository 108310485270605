export default {
    /**
     *
     * @param {string} path
     * @param {string} fileName
     */
    exportFile(path, fileName) {
        axios
            .get(path, { responseType: "blob" })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data],{
                  type: "application/octet-stream"
                }));
                this.downloadFile(url,fileName)
            })
            .catch((error) => {
                this.showErrorMessage(error);
            });
    }
}
