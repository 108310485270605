export default [
  {
    path: '/employee-position',
    meta: { label: 'Employee Positions' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'employee-position',
        meta: {
          permission: 'show_all_employee_positions'
        },
        component: () => import('./../../views/employee-position/index.vue'),
      },
      {
        path: 'create',
        name: 'create-employee-position',
        meta: {
          permission: 'create_employee_position'
        },
        component: () => import('./../../views/employee-position/create.vue'),
      },
      {
        path: 'edit/:id',
        name: 'edit-employee-position',
        meta: {
          permission: 'edit_employee_position'
        },
        component: () => import('./../../views/employee-position/edit.vue'),
      },
    ]
  }
]
