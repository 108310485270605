export default [
  {
    path: "coaching",
    meta: { label: "Coaching" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "coaching",
        meta: { permission: "show_all_coaching" },
        component: () => import("./../../views/coaching/index")
      }
    ]
  }
];
