import DashboardRoutes from './routes/Dashboard.routes'
import ChangePasswordRoutes from './routes/ChangePassword.routes'
import ChangeProfilePictureRoutes from './routes/ChangeProfilePicture.routes'
import Profile from './routes/Profile.routes'
import SupportRoutes from './routes/Support.routes'
import ThemeRoutes from '../theme'
import SettingsRoutes from '../settings'
import VisitsRoutes from '../visits'
import RequestsRoutes from '../requests'
import ToolsRoutes from '../tools'
import ReportRoutes from '../reports'
import WidgetsRoutes from './routes/Widgets.routes'
import WidgetSettingsViewRoutes from './routes/WidgetSettingsView.routes'
import LockScreenRoutes from './routes/LockScreen.routes'
import CalendarRoutes from './routes/Calendar.routes'
import HelpRoutes from './routes/Help.routes'
import CommunicationRoutes from '../communications/index'
import coachingRoutes from '../coaching/index'
import quizRoutes from '../quiz/index'
export default [
    ...LockScreenRoutes,
    ...DashboardRoutes,
    ...ChangePasswordRoutes,
    ...ChangeProfilePictureRoutes,
    ...SupportRoutes,
    ...ThemeRoutes,
    ...SettingsRoutes,
    ...VisitsRoutes,
    ...RequestsRoutes,
    ...ToolsRoutes,
    ...ReportRoutes,
    // ...DashboardChartsRoutes,
    // ...ChartLabelsRoutes,
    ...WidgetsRoutes,
    ...WidgetSettingsViewRoutes,
    ...CalendarRoutes,
    ...HelpRoutes,
    ...CommunicationRoutes,
    ...Profile,
    ...coachingRoutes,
    ...quizRoutes,
]
