import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'vuetify/dist/vuetify.min.js'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary: colors.blue.base,
                secondary: colors.shades.white,
                accent: colors.blue.accent1,
                red: colors.red.base,
                yellow: colors.yellow.darken3,
            },
            dark: {
                primary: colors.blue.darken2,
                secondary: colors.shades.white,
                accent: colors.shades.black,
                red: colors.red.base,
                yellow: colors.yellow.darken3,
            }
        }
    }
}

export default new Vuetify(opts)