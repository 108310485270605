import GeneralSettingsRoutes from './general.routes';
import StructureSettingsRoutes from './structure.routes';
import UserSettingsRoutes from './user.routes';
import WidgetSettingsRoutes from './widget.routes';
import VisitSettingsRoutes from './visit.routes';
import RequestSettingsRoutes from './request.routes';
import SalesSettingsRoutes from './sales.routes';
import PositionsRoutes from './positions.routes';
import employeePositionsRoutes from './employee-positions.routes';
import trainingRoutes from './training.routes';
import approvalRoutes from './approval.routes';
import BudgetRoutes from './budget.routes';
import GroupingRoutes from './grouping.routes';


export default [
  {
    path: '/setting-links',
    meta: { label: 'Settings' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_setting_links' },
        component: () => import('./../../views/settings/SettingLinks'),
      },
      ...GeneralSettingsRoutes,
      ...UserSettingsRoutes,
      ...StructureSettingsRoutes,
      ...WidgetSettingsRoutes,
      ...VisitSettingsRoutes,
      ...RequestSettingsRoutes,
      ...SalesSettingsRoutes,
      ...PositionsRoutes,
      ...employeePositionsRoutes,
      ...trainingRoutes,
      ...approvalRoutes,
      ...BudgetRoutes,
      ...GroupingRoutes,
      // Add more routes as needed...

    ]
  },
];
