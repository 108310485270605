import {capitalize} from "../filters"

const title = (store) => {
  return (to, from, next) => {
    document.title = capitalize(to.name || 'Gemstone')
    next()
  }

}


const initialState = (store) => {
  return (to, from, next) => {
    if (to.name !== "Login") {
      store.dispatch('initializeStore').then(ok => {
        if (ok) next()
        else next({name: 'Login'})
      })
    } else {
      next()
    }
  }
}

const lockAccountState = (store) => {
  return (to, from, next) => {
    if (store.getters['authentication/getAccountLock']) {
      if (to.name === "LockScreen") {
        next()
      } else {
        next({name: 'LockScreen'})
      }
    } else {
      next()
    }
  }
}

export const login = (store)=>{
  return (to, from, next) => {
    if (!store.getters['authentication/isAuthenticated']) {
      next()
    } else {
      next({name: 'Main Dashboard'})
    }
  }
}


// export const permission = (to, from, next) => {
//     if (!to.meta.permission) {
//         next()
//     } else if ('show_hel' == to.meta.permission) next()
//     else {
//         next({ name: from.name })
//     }
// }

export default {
  title,
  initialState,
  lockAccountState,
  // permission
}
