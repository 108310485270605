export default [
  {
    path: 'notifications',
    redirect: '/notifications/alerts',
    name: 'Notifications',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'alerts',
        name: 'Alerts',
        component: () => import('./../../../views/notifications/Alerts')
      },
      {
        path: 'badge',
        name: 'Badge',
        component: () => import('./../../../views/notifications/Badges')
      },
      {
        path: 'modals',
        name: 'Modals',
        component: () => import('./../../../views/notifications/Modals')
      },
      {
        path: 'toaster',
        name: 'Toaster',
        component: () => import('./../../../views/notifications/Toaster')
      }
    ]
  },
]
