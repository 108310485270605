export default [
    
    {
        path: '/budget-settings',
        meta: { label: 'Budget Settings' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                name: 'budget-settings',
                meta:{
                    permission: 'show_all_budget_settings'
                },
                component: () =>
                    import ('./../../views/budgetsettings/index.vue'),
            },
            // {
            //     path: 'create', 
            //     meta: { 
            //         label: 'Create Budget Settings',
            //         permission:'create_budget_setting'
            //     },
            //     name: 'CreateBudgetSettings',
            //     component: () =>
            //         import ('./../../views/budgetsettings/create.vue'),
            // },
            {
                path: ':id/edit',
                meta: { 
                    label: 'Edit Budget Settings',
                    permission:'edit_budget_setting' 
                },
                name: 'EditBudgetSettings',
                component: () =>
                    import ('./../../views/budgetsettings/edit.vue')
            },
            {
                path: ':id',
                meta: { 
                    label: 'Show Budget Settings',
                    permission:'show_single_budget_setting' 
                },
                name: 'ShowBudgetSettings',
                component: () =>
                    import ('./../../views/budgetsettings/show.vue')
            },
        ]
    },

]