export default [
  {
    path: 'icon',
    redirect: '/icon/coreui-icons',
    name: 'CoreUI Icons',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'coreui-icons',
        name: 'Standard CoreUI Icons',
        component: () => import('./../../../views/icons/CoreUIIcons')
      },
      {
        path: 'flags',
        name: 'Flags',
        component: () => import('./../../../views/icons/Flags')
      }
    ]
  },
]
