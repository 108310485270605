import { fileNameFromPath } from "../filters";

export default {
    downloadFile(url, fileName = '') {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName == '' ? fileNameFromPath(url) : fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}