export default [
  {
    path: "plans",
    meta: { label: "Visits Plans" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "plan visits",
        meta: { permission: "show_all_plan_visits" },
        component: () => import("./../../views/visitPlans/PlanVisits")
      },
      {
        path: 'scheduler',
        name: 'Scheduler',
        meta: { permission: 'show_menu_plan_scheduler' },
        component: () =>
          import('./../../views/scheduler/index.vue')
      },
      {
        path: '/start-point',
        name: 'start point',
        meta: { permission: 'show_menu_start_point' },
        component: () =>
          import('./../../views/StartPoint/index.vue')
      },
      {
        path: "create",
        meta: { label: "Create Visit Plan", permission: "create_plan_visits" },
        name: "CreateVisitPlan",
        component: () => import("../../views/DailyPlanVisit/index.vue")
      },
      {
        path: "bricks",
        meta: { label: "Brick View", permission: "show_all_brick_view_plans" },
        name: "Plan Brick View",
        component: () => import("../../views/visitPlansBrick/index.vue")
      },
      // {
      //     path: 'daily',
      //     meta: { label: 'Daily View', permission: 'show_all_plan_daily_views' },
      //     name: 'PlanDailyView',
      //     component: () =>
      //         import ('../../views/DailyPlanVisit/index.vue')
      // },
      {
        path: ":id",
        meta: {
          label: "Show Visit Plan",
          permission: "show_single_plan_visits"
        },
        name: "ShowPlanVisit",
        component: () => import("./../../views/visitPlans/PlanVisit")
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Plan Visit', permission: 'edit_plan_visits' },
        name: 'EditPlanVisit',
        component: () => import('./../../views/visitPlans/edit.vue'),
      },
    ]
  },

  {
    path: '/change-plans',
    meta: { label: 'Change Plan' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: '' },
        name: 'Change Plan',
        component: () => import('./../../views/changePlan/index.vue'),
      },
    ]
  },

  {
    path: '/change-plan-approvals',
    meta: { label: 'Change Plan Approvals' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: '' },
        name: 'Change Plan Approvals',
        component: () => import('./../../views/changePlan/approvals.vue'),
      },
    ]
  },
  {
    path: "owplanvisits",
    meta: { label: "OW & Activities" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "ow plan visits",
        meta: { permission: "show_all_ow_plan_visits" },
        component: () => import("../../views/owplanvisits/OwPlanVisits")
      },
      {
        path: "create",
        meta: {
          label: "OW & Activities Create",
          permission: "create_ow_plan_visits"
        },
        name: "CreateOwPlanVisit",
        component: () => import("../../views/owplanvisits/CreateOwPlanvisit")
      },
      {
        path: ":id",
        meta: {
          label: "OW & Activities Details",
          permission: "show_single_ow_plan_visits"
        },
        name: "OwPlanVisit",
        component: () => import("./../../views/owplanvisits/OwPlanVisit")
      }
      // {
      //   path: ':id/edit',
      //   meta: { label: 'Edit Office Work Type' },
      //   name: 'EditVacationType',
      //   component: EditOfficeWorkType
      // },
    ]
  },
  {
    path: "/planapprovals",
    meta: { label: "Plan Approvals" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "plan approvals",
        meta: { permission: "show_all_plan_approvals" },
        component: () => import("./../../views/planapproval/PlanApprovals")
      }
    ]
  },
  {
    path: "/double_plan",
    meta: { label: "Double Plans" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        meta: { permission: "show_all_double_plans" },
        name: "double plan",
        component: () => import("./../../views/doubleplan/DoublePlan")
      }
    ]
  },
  {
    path: "/automatic_plan",
    meta: { label: "Automatic Plans" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        meta: { permission: "show_all_plan_visits" },
        name: "Automatic plan",
        component: () => import("./../../views/AutomaticPlanVisit/index.vue")
      }
    ]
  }
];
