import ViewComponentRoutes from './views/component.routes';
import ViewButtonRoutes from './views/button.routes';
import ViewFormRoutes from './views/form.routes';
import ViewIconRoutes from './views/icon.routes';
import ViewNoteRoutes from './views/note.routes';
import ViewPluginRoutes from './views/plugin.routes';
import ViewNotificationRoutes from './views/notification.routes';
import ViewEmailRoutes from './views/email.routes';
import ViewUiKitsRoutes from './views/uikit.routes';
import ViewResourceRoutes from './views/resource.routes';
import ViewEditorRoutes from './views/editor.routes';
import ViewBreadRoutes from './views/bread.routes';
import ViewGoogleMapRoutes from './views/googlemap.routes';
export default [
  //Notes
  ...ViewNoteRoutes,
  ...ViewPluginRoutes,
  ...ViewUiKitsRoutes,
  ...ViewNotificationRoutes,
  ...ViewBreadRoutes,
  ...ViewEmailRoutes,
  ...ViewResourceRoutes,
  ...ViewComponentRoutes,
  ...ViewButtonRoutes,
  ...ViewEditorRoutes,
  ...ViewFormRoutes,
  ...ViewGoogleMapRoutes,
  ...ViewIconRoutes,
  //Todo Test
  {
    path: 'todos',
    name: 'Todos',
    component: () => import('./../../views/todos/todos'),
    meta: { title: 'Todo' }
  },
]
