import moment from 'moment-timezone'

moment.tz.setDefault("Africa/Cairo");
export default {
    crmDateFormat(date, format = 'YYYY-MM-DD HH:mm:ss') {
        if (date == null) {
            return null;
        }
      return moment(date).format(format);
    },
    // inset it to datetime component only as input
    toIsoString(date){
        return (new Date(this.crmDateFormat(date)).toISOString())
    },
    dateFromNow(days, format = 'YYYY-MM-DD') {
      return moment().add(days,'days').format(format);
    },
    getDateNow(format = 'YYYY-MM-DD HH:mm:ss'){
        return moment().format(format)
    }
}


