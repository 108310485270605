export default [{
        path: '/actualvisitsettings',
        meta: { label: 'Actual Visit Settings' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                meta:{ permission: 'show_all_actual_visit_settings' },
                name: 'actual-visit-settings',
                component: () =>
                    import ('./../../../views/actualvisitsettings/index'),
            },
            {
                path: 'create',
                meta: { label: 'Actual Visit Setting Create', permission: 'create_actual_visit_settings' },
                name: 'CreateActualVisitSetting',
                component: () =>
                    import ('./../../../views/actualvisitsettings/create'),
            },
            {
                path: ':id',
                meta: { label: 'Actual Visit Setting Details', permission: 'show_single_actual_visit_settings' },
                name: 'ActualVisitSetting',
                component: () =>
                    import ('./../../../views/actualvisitsettings/show'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Actual Visit Setting', permission: 'edit_actual_visit_settings' },
                name: 'EditActualVisitSetting',
                component: () =>
                    import ('./../../../views/actualvisitsettings/edit')
            },
        ]
    },
    {
        path: '/unplanned-visits',
        meta: { label: 'Unplanned Visits' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                meta:{ permission: 'show_all_unplanned-visits' },
                name: 'unplanned-visits',
                component: () =>
                    import ('./../../../views/unplannedvisits/index'),
            },
            {
                path: 'create',
                meta: { label: 'Unplanned Visit Create', permission: 'create_unplanned-visits' },
                name: 'CreateUnplannedVisit',
                component: () =>
                    import ('./../../../views/unplannedvisits/create'),
            },
            {
                path: ':id',
                meta: { label: 'Unplanned Visit Details', permission: 'show_single_unplanned-visits' },
                name: 'UnplannedVisit',
                component: () =>
                    import ('./../../../views/unplannedvisits/show'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Unplanned Visit', permission: 'edit_unplanned-visits' },
                name: 'EditUnplannedVisit',
                component: () =>
                    import ('./../../../views/unplannedvisits/edit')
            },
        ]
    },
    {
        path: '/user_actual_start_day',
        meta: { label: 'User Actual Start Day' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                meta:{ permission: 'show_all_start_actual_day_users' },
                name: 'users-actual-start-day',
                component: () =>
                    import ('./../../../views/user_actual_start_day/index'),
            },
            {
                path: 'create',
                meta: { label: 'Create User Actual Start Day', permission: 'create_start_actual_day_users' },
                name: 'CreateUserActualStartDay',
                component: () =>
                    import ('./../../../views/user_actual_start_day/create'),
            },
            {
                path: ':id',
                meta: { label: 'Show User Actual Start Day', permission: 'show_single_start_actual_day_users' },
                name: 'UserActualStartDay',
                component: () =>
                    import ('./../../../views/user_actual_start_day/show'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit User Actual Start Day', permission: 'edit_start_actual_day_users' },
                name: 'EditUserActualStartDay',
                component: () =>
                    import ('./../../../views/user_actual_start_day/edit')
            },
        ]
    },
    {
        path: '/actual_visit_inputs',
        meta: { label: 'Actual Visit Inputs' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
            path: '',
            meta:{ permission: 'edit_av_required_inputs' },
            name: 'actual-visit-inputs',
            component: () =>
                import ('./../../../views/actualplans/EditActualVisitInput'),
        }, ]
    },

    {
        path: '/type-distance',
        meta: { label: 'Account Type Distance' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                meta:{ permission: 'show_all_account_type_distances' },
                name: 'type-distances',
                component: () =>
                    import ('./../../../views/typeDistance/index'),
            },
            {
                path: 'create',
                meta: { label: 'Type Distance Create', permission: 'create_account_type_distances' },
                name: 'CreateTypeDistance',
                component: () =>
                    import ('./../../../views/typeDistance/create'),
            },
            {
                path: ':id',
                meta: { label: 'Type Distance Details', permission: 'show_single_account_type_distances' },
                name: 'TypeDistance',
                component: () =>
                    import ('./../../../views/typeDistance/show'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Type Distance' , permission: 'edit_account_type_distances'},
                name: 'EditTypeDistance',
                component: () =>
                    import ('./../../../views/typeDistance/edit')
            },
        ]
    },

    {
        path: '/visitfeedbacks',
        meta: { label: 'Visit Feedbacks' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                meta:{ permission: 'show_all_visit_feedbacks' },
                name: 'visit-feedbacks',
                component: () =>
                    import ('./../../../views/visitfeedbacks/VisitFeedbacks'),
            },
            {
                path: 'create',
                meta: { label: 'Visit Feedback Create', permission: 'create_visit_feedbacks' },
                name: 'CreateVisitFeedback',
                component: () =>
                    import ('./../../../views/visitfeedbacks/CreateVisitFeedback'),
            },
            {
                path: ':id',
                meta: { label: 'Visit Feedback Details', permission: 'show_single_visit_feedbacks' },
                name: 'VisitFeedback',
                component: () =>
                    import ('./../../../views/visitfeedbacks/VisitFeedback'),
            },
            {
                path: ':id/edit',
                meta: { label: 'Edit Visit Feedback' , permission: 'edit_visit_feedbacks'},
                name: 'EditVisitFeedback',
                component: () =>
                    import ('./../../../views/visitfeedbacks/EditVisitFeedback')
            },
        ]
    },

]
