export default [{
    path: '/lockscreen',
    name: 'Auth',
    component: {
        render(c) { return c('router-view') }
    },
    children: [{
        path: '',
        name: 'LockScreen',
        meta: { permission:'lock_account' },
        component: () =>
            import ('../../../views/pages/LockScreen.vue')
    }, ]
}]