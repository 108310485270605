export default [
  {
    path: 'logactivities',
    meta: { label: 'Log Activities' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'Log-activity',
        meta: { permission: 'show_all_log_activities' },
        component: () => import('./../../views/logactivities/LogActivities'),
      },
      // {
      //   path: '',
      //   name: 'log-map',
      //   meta: { permission:'show_all_log_activities' },
      //   component: () => import('./../../views/logactivities/LogMap.vue'),
      // },
    ]
  },
  {
    path: 'imports',
    meta: { label: 'Files' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'files',
        meta: { permission: 'show_all_imports' },
        component: () => import('./../../views/imports/Imports'),
      },
      {
        path: ':id/errors',
        meta: { permission: 'show_all_imports' },
        name: 'Errors',
        component: () => import('./../../views/imports/Errors.vue'),
      },
    ]
  },
  //   {
  //     path: 'map',
  //     meta: { label: 'Log Activity Map', permission: '' },
  //     name: 'log-map',
  //     beforeEnter (to, from, next) {
  //        if(to.params.position!=null){
  //         next()
  //         return true
  //        }
  //       next({name:'Log-activity'})
  //     },
  //     beforeLeave (to, from, next) {
  //        console.log(from)
  //     },
  //     component: () => import('./../../views/logactivities/LogMap.vue'),
  // },
]
