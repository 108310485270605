import {getLazyValue} from '../helpers';

/**
 * This function allow to auto detect an external load of the Google Maps API
 * or load it dynamically from our component.
 *
 * @param  {Function} resolveFn the function that indicates to the plugin that Google Maps is loaded
 * @param  {Function} customCallback the custom callback to execute when the plugin load. This option will be removed on the next major release
 */
function createCallbackAndChecksIfMapIsLoaded(resolveFn, customCallback) {
  let callbackExecuted = false;

  window.GoogleMapsCallback = () => {
    try {
      resolveFn();
      callbackExecuted = true;
      // TODO: this should be removed on the next major release
      if (customCallback) {
        customCallback();
      }
    } catch (error) {
      window.console.error('Error executing the GoogleMapsCallback', error);
    }
  };

  let timeoutId = setTimeout(() => {
    let intervalId = setInterval(() => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = undefined;
      }

      if (
        (window && window.google && window.google.maps) != null &&
        !callbackExecuted
      ) {
        window.GoogleMapsCallback();
        callbackExecuted = true;
      }

      if (callbackExecuted) {
        clearInterval(intervalId);
        intervalId = undefined;
      }
    }, 500);
  }, 1000);
}

/**
 * This function is a factory of the promise lazy creator
 * it helps you creating the function that will call the
 * Google Maps API in an async way
 *
 * @param  {Function} googleMapsApiInitializer function that initialize the Google Maps API
 * @param  {Object} GoogleMapsApi Vue instance that will help to know if the google API object is ready
 * @returns {Function}
 */
function getPromiseLazyCreatorFn(googleMapsApiInitializer, GoogleMapsApi) {
  return (options) => {
    /**
     * Things to do once the API is loaded
     *
     * @returns {Object} the Google Maps API object
     */
    function onMapsReady() {
      GoogleMapsApi.isReady = true;
      return window.google;
    }

    // If library should load the API
    if ((options && options.load && options.load.key) || options.dynamicLoad) {
      return getLazyValue(() => {
        // This will only be evaluated once
        if (typeof window === 'undefined') {
          // server side -- never resolve this promise
          return new Promise(() => {
          }).then(onMapsReady);
        }

        return new Promise((resolve, reject) => {
          try {
            createCallbackAndChecksIfMapIsLoaded(
              resolve,
              window[options && options.load && options.load.customCallback]
            );

            if (!options.dynamicLoad) {
              googleMapsApiInitializer(options.load, options.loadCn);
            }
          } catch (err) {
            reject(err);
          }
        }).then(onMapsReady);
      });
    }

    // If library should not handle API, provide
    // end-users with the global `GoogleMapsCallback: () => undefined`
    // when the Google Maps API has been loaded
    const promise = new Promise((resolve) => {
      if (typeof window === 'undefined') {
        // Do nothing if run from server-side
        return;
      }
      createCallbackAndChecksIfMapIsLoaded(
        resolve,
        window[options && options.load && options.load.customCallback]
      );
    }).then(onMapsReady);

    return getLazyValue(() => promise);
  };
}

export default getPromiseLazyCreatorFn;
