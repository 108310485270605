export default {
  showErrorMessage(error) {
    if(error instanceof TypeError){
      this.flash("Oops! Something went wrong. Please Contact Your Service Provider",'error')
      return
    }
    if (error.response.data.exception) {
      this.flash("Oops! Something Went Wrong. Please Try Again Later", 'error');
      return
    }
    if (typeof (error.response.data.message) === 'string') {
      this.flash(
        error.response.data.message,
        "error"
      );
      return
    }
    const failures = error.response.data.message;
    Object.values(failures).forEach((failure) => {
      this.flash(failure, "error");
    });
  },
}
