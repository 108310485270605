export default {
    /**
     *
     * @param {string} path
     * @param {FormData} formData
     */
    importFile(path, formData) {
        return axios
            .post(path, formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
                onUploadProgress: function(progressEvent) {
                    this.uploadPercentage = parseInt(
                        Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    );
                }.bind(this),
            })
            .then((response) => {
                this.successModal = false;
                this.updateModal = false;
                this.progressBar = false; 
                this.flash("File Uploaded Successfully");
                if (this.hasOwnProperty('getData')) this.getData()
            })
            .catch((error) => {
                this.progressBar = false;
                this.successModal = false;
                this.updateModal = false;
                if (error.response.data.message) {
                    this.showErrorMessage(error)
                }
                if (!error.response.data.failures) return;
                this.showExcelValidationFailures(error)
            })
    },
}