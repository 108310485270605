export default [
    {
        path: 'employee-route',
        meta: { label: 'Employee Route Report', permission: 'show_reports_employee_route' },
        name: 'employee-route',
        component: () => import('./../../views/reports/EmployeeRouteReport.vue'),
    },
    {
        path: 'team-locations',
        meta: { label: 'Team Locations Report', permission: 'show_reports_team_locations' },
        name: 'team-locations',
        component: () => import('./../../views/reports/TeamLocationsReport.vue'),
    },
    {
        path: 'out-of-location',
        meta: { label: 'Out Of Locations Report', permission: 'show_reports_out_of_locations' },
        name: 'out-of-location',
        component: () => import('./../../views/reports/OutOfLocationReport.vue'),
    },
    {
        path: 'brick-mapping-positions',
        meta: { label: 'Brick Mapping Positions', permission: 'show_reports_out_of_locations' },
        name: 'brick-mapping-positions',
        component: () => import('./../../views/reports/BrickMappingPositionsReport.vue'),
    },
    {
        path: 'employee-tracking-map',
        meta: { label: 'Employee Tracking Map', permission: 'show_reports_out_of_locations' },
        name: 'employee-tracking-map',
        component: () => import('./../../views/reports/UserTrackingReport.vue'),
    },
    {
        path: 'gps-visits',
        meta: { label: 'GPS Visits', permission: 'show_gps_visits' },
        name: 'gps-visits',
        component: () => import('./../../views/reports/gpsVisitsReport.vue'),
    },

]
