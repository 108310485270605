export default [
  {
    path: "coaching",
    meta: { label: "Coaching Settings" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "coachingsettings",
        meta: { label: "Coaching Settings" },
        component: () => import("./../../../components/Coaching/Coaching.vue")
      },
      {
        path: "types",
        meta: { label: "Types", permission: "show_coaching_types" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "CoachingTypes",
            meta: { label: "Types", permission: "show_all_coaching_types" },
            component: () =>
              import("./../../../views/coachingsettings/types/index.vue")
          },
          {
            path: "create",
            name: "CreateCoachingType",
            meta: { label: "Create Type", permission: "create_coaching_types" },
            component: () =>
              import("./../../../views/coachingsettings/types/create.vue")
          },
          {
            path: ":id",
            name: "ShowCoachingType",
            meta: {
              label: "Show Type",
              permission: "show_single_coaching_types"
            },
            component: () =>
              import("./../../../views/coachingsettings/types/show.vue")
          },
          {
            path: ":id/edit",
            name: "EditCoachingType",
            meta: { label: "Edit Type", permission: "edit_coaching_types" },
            component: () =>
              import("./../../../views/coachingsettings/types/edit.vue")
          }
        ]
      },
      {
        path: "categories",
        meta: { label: "Categories", permission: "show_coaching_categories" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "CoachingCategories",
            meta: {
              label: "Categories",
              permission: "show_all_coaching_categories"
            },
            component: () =>
              import("./../../../views/coachingsettings/categories/index.vue")
          },
          {
            path: "create",
            name: "CreateCoachingCategory",
            meta: {
              label: "Create Category",
              permission: "create_coaching_categories"
            },
            component: () =>
              import("./../../../views/coachingsettings/categories/create.vue")
          },
          {
            path: ":id",
            name: "ShowCoachingCategory",
            meta: {
              label: "Show Category",
              permission: "show_single_coaching_categories"
            },
            component: () =>
              import("./../../../views/coachingsettings/categories/show.vue")
          },
          {
            path: ":id/edit",
            name: "EditCoachingCategory",
            meta: {
              label: "Edit Category",
              permission: "edit_coaching_categories"
            },
            component: () =>
              import("./../../../views/coachingsettings/categories/edit.vue")
          }
        ]
      },
      // Questions
      {
        path: "questions",
        meta: { label: "Questions", permission: "show_coaching_questions" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "CoachingQuestions",
            meta: {
              label: "Questions",
              permission: "show_all_coaching_questions"
            },
            component: () =>
              import("./../../../views/coachingsettings/questions/index.vue")
          },
          {
            path: "create",
            name: "CreateCoachingQuestion",
            meta: {
              label: "Create Question",
              permission: "create_coaching_questions"
            },
            component: () =>
              import("./../../../views/coachingsettings/questions/create.vue")
          },
          {
            path: ":id",
            name: "ShowCoachingQuestion",
            meta: {
              label: "Show Question",
              permission: "show_single_coaching_questions"
            },
            component: () =>
              import("./../../../views/coachingsettings/questions/show.vue")
          },
          {
            path: ":id/edit",
            name: "EditCoachingQuestion",
            meta: {
              label: "Edit Question",
              permission: "edit_coaching_questions"
            },
            component: () =>
              import("./../../../views/coachingsettings/questions/edit.vue")
          }
        ]
      },
      {
        path: '/coaching-settings',
        meta: { label: 'Coaching Settings' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            // {
            //     path: '',
            //     name: 'expense-settings',
            //     meta: { permission: 'show_all_expense_settings' },
            //     component: () => import('../../views/expenseSettings/index.vue'),
            // },
            {
                path: ':id/edit',
                meta: { label: 'Edit Coaching Settings', permission: 'edit_coaching_settings' },
                name: 'EditCoachingSettings',
                component: () => import('../../../views/coachingSettings/edit.vue')
            },
        ]
    },
    ]
  },

  // Evaluator Settings
  {
    path: "evaluatorsettings",
    meta: {
      label: "Evaluator Settings",
      permission: "show_coaching_evaluator_settings"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "EvaluatorSettings",
        meta: {
          label: "Evaluator Settings",
          permission: "show_all_coaching_evaluator_settings"
        },
        component: () =>
          import("./../../../views/coachingsettings/evaluator/index.vue")
      },
      {
        path: "create",
        name: "CreateEvaluatorSetting",
        meta: {
          label: "Create Evaluator Setting",
          permission: "create_coaching_evaluator_settings"
        },
        component: () =>
          import("./../../../views/coachingsettings/evaluator/create.vue")
      },
      {
        path: ":id",
        name: "ShowEvaluatorSetting",
        meta: {
          label: "Show Evaluator Setting",
          permission: "show_single_coaching_evaluator_settings"
        },
        component: () =>
          import("./../../../views/coachingsettings/evaluator/show.vue")
      },
      {
        path: ":id/edit",
        name: "EditEvaluatorSetting",
        meta: {
          label: "Edit Evaluator Setting",
          permission: "edit_coaching_evaluator_settings"
        },
        component: () =>
          import("./../../../views/coachingsettings/evaluator/edit.vue")
      }
    ]
  }
];
