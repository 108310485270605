export default [
  {
    path: "custom-report",
    meta: { label: "Custom Report", permission: "access_button_custom_report" },
    name: "custom-report",
    component: () => import("./../../views/reports/customReports/index.vue")
  },
  {
    path: "ceiling-report",
    meta: { label: "Ceiling Report", permission: "access_button_ceiling_report" },
    name: "ceiling-report",
    component: () => import("./../../views/reports/CeilingReport.vue")
  }
];
