export default [
  {
    path: 'resource',
    meta: { label: 'Resources' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: ':bread/resource',
        component: () => import('./../../../views/resources/Resources'),
      },
      {
        path: ':bread/resource/create',
        meta: { label: 'Create Resource' },
        name: 'CreateResource',
        component: () => import('./../../../views/resources/CreateResources')
      },
      {
        path: ':bread/resource/:id',
        meta: { label: 'Resource Details' },
        name: 'Resource',
        component: () => import('./../../../views/resources/Resource'),
      },
      {
        path: ':bread/resource/:id/edit',
        meta: { label: 'Edit Resource' },
        name: 'Edit Resource',
        component: () => import('./../../../views/resources/EditResource')
      },
      {
        path: ':bread/resource/:id/delete',
        meta: { label: 'Delete Resource' },
        name: 'Delete Resource',
        component: () => import('./../../../views/resources/DeleteResource')
      },
    ]
  },
]
