export default[
  {
    path: 'bread',
    meta: { label: 'Bread' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        component: () => import('./../../../views/bread/Breads'),
      },
      {
        path: 'create',
        meta: { label: 'Create Bread' },
        name: 'CreateBread',
        component: () => import('./../../../views/bread/CreateBread')
      },
      {
        path: ':id',
        meta: { label: 'Bread Details' },
        name: 'Bread',
        component: () => import('./../../../views/bread/Bread'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Bread' },
        name: 'Edit Bread',
        component: () => import('./../../../views/bread/EditBread')
      },
      {
        path: ':id/delete',
        meta: { label: 'Delete Bread' },
        name: 'Delete Bread',
        component: () => import('./../../../views/bread/DeleteBread')
      },
    ]
  },
]
