import axios from 'axios'
import {storePromise} from '../store';

// axios.defaults.baseURL = import.meta.env.BASE_API_URL

storePromise.then(store=>{
  axios.interceptors.request.use(
    (config) => {
      store.dispatch("app/loadProgressBar")
      return config;
    },
    (error) => {
      store.dispatch("app/unLoadProgressBar")
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      store.dispatch("app/unLoadProgressBar")
      return response;
    },
    (error) => {
      store.dispatch("app/unLoadProgressBar")
      return Promise.reject(error);
    }
  );

  window.axios = axios
})

