export default {
    /**
     *
     * @param {string} path
     * @param {string} type
     */
    get(path, type) {
        axios
            .get(path)
            .then((response) => {
                if (type == 'mail' || type == 'roles') {
                    this.items = response.data
                    return
                }
                if (type == 'deletedImports') {
                    this.deleteditems = response.data.deletedimports;
                    this.deletedtotal = response.data.totaldeletedimports;
                    this.you = response.data.you;
                    return
                }
                this.items = response.data[type];
                this.you = response.data.you;
                this.total = response.data.total;
                if (type == "accounts") {
                    this.checkedLastLevel = response.data.checkedLastLevel;
                }
            })
            .catch((error) => {
                this.showErrorMessage(error)
            });
    }
}