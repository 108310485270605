export default [{
    path: '/maps',
    meta: { label: 'Google Maps' },
    component: {
        render(c) { return c('router-view') }
    },
    children: [{
        path: '',
        component: () =>
            import ('../../views/maps/Index.vue')
    }]

}];