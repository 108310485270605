export default [
  {
    path: '/brands',
    meta: { label: 'Brands' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_brands' },
        name: 'brands',
        component: () => import('./../../../views/brands/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Brand Create', permission: 'create_brands' },
        name: 'CreateBrand',
        component: () => import('./../../../views/brands/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Brand Details', permission: 'show_single_brands' },
        name: 'Brand',
        component: () => import('./../../../views/brands/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Brand', permission: 'edit_brands' },
        name: 'EditBrand',
        component: () => import('./../../../views/brands/edit.vue')
      },
    ]
  },
  {
    path: '/classifications',
    meta: { label: 'Classifications' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_classifications' },
        name: 'classifications',
        component: () => import('./../../../views/classifications/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Classification Create', permission: 'create_classifications' },
        name: 'CreateClassification',
        component: () => import('./../../../views/classifications/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Classification Details', permission: 'show_single_classifications' },
        name: 'Classification',
        component: () => import('./../../../views/classifications/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Classification', permission: 'edit_classifications' },
        name: 'EditClassification',
        component: () => import('./../../../views/classifications/edit.vue')
      },
    ]
  },
  {
    path: '/families',
    meta: { label: 'Families' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'families',
        meta: { permission: 'show_all_families' },
        component: () => import('./../../../views/families/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Family Create', permission: 'create_families' },
        name: 'CreateFamily',
        component: () => import('./../../../views/families/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Family Details', permission: 'show_single_families' },
        name: 'Family',
        component: () => import('./../../../views/families/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Family', permission: 'edit_families' },
        name: 'EditFamily',
        component: () => import('./../../../views/families/edit.vue')
      },
    ]
  },
  {
    path: '/manufacturers',
    meta: { label: 'Manufacturers' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'manufacturers',
        meta: { permission: 'show_all_manufacturers' },
        component: () => import('./../../../views/manufacturers/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Manufacturer Create', permission: 'create_manufacturers' },
        name: 'CreateManufacturer',
        component: () => import('./../../../views/manufacturers/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Manufacturer Details', permission: 'show_single_manufacturers' },
        name: 'Manufacturer',
        component: () => import('./../../../views/manufacturers/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Manufacturer', permission: 'edit_manufacturers' },
        name: 'EditManufacturer',
        component: () => import('./../../../views/manufacturers/edit.vue')
      },
    ]
  },
  {
    path: '/product_types',
    meta: { label: 'Product Types' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'product-types',
        meta: { permission: 'show_all_producttypes' },
        component: () => import('./../../../views/product_types/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Product_type Create', permission: 'create_producttypes' },
        name: 'CreateProduct_type',
        component: () => import('./../../../views/product_types/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Product_type Details', permission: 'show_single_producttypes' },
        name: 'Product_type',
        component: () => import('./../../../views/product_types/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Product_type', permission: 'edit_producttypes' },
        name: 'EditProduct_type',
        component: () => import('./../../../views/product_types/edit.vue'),
      },
    ]
  },
  {
    path: '/price_types',
    meta: { label: 'Price Types' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'price-types',
        meta: { permission: 'show_all_product_prices' },
        component: () => import('./../../../views/price_types/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Price_type Create', permission: 'create_product_prices' },
        name: 'CreatePrice_type',
        component: () => import('./../../../views/price_types/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Price_type Details', permission: 'show_single_product_prices' },
        name: 'Price_type',
        component: () => import('./../../../views/price_types/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Price_type', permission: 'edit_product_prices' },
        name: 'EditPrice_type',
        component: () => import('./../../../views/price_types/edit.vue'),
      },
    ]
  },
  {
    path: '/presentations',
    meta: { label: 'Presentations' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_presentations' },
        name: 'presentations',
        component: () => import('./../../../views/presentations/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Presentation Create', permission: 'create_presentations' },
        name: 'CreatePresentation',
        component: () => import('./../../../views/presentations/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Presentation Details', permission: 'show_single_presentations' },
        name: 'Presentation',
        component: () => import('./../../../views/presentations/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Presentation', permission: 'edit_presentations' },
        name: 'EditPresentation',
        component: () => import('./../../../views/presentations/edit.vue')
      },
    ]
  },
  {
    path: '/messages',
    meta: { label: 'Product Messages' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_messages' },
        name: 'messages',
        component: () => import('./../../../views/productmessages/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Message Create', permission: 'create_messages' },
        name: 'CreateMessage',
        component: () => import('./../../../views/productmessages/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Message Details', permission: 'show_single_messages' },
        name: 'ProductMessage',
        component: () => import('./../../../views/productmessages/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Message', permission: 'edit_messages' },
        name: 'EditMessage',
        component: () => import('./../../../views/productmessages/edit.vue')
      },
    ]
  },
  {
    path: '/products',
    meta: { label: 'Products' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'products',
        meta: { permission: 'show_all_products' },
        component: () => import('./../../../views/products/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Product Create', permission: 'create_products' },
        name: 'CreateProduct',
        component: () => import('./../../../views/products/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Product Details', permission: 'show_single_products' },
        name: 'Product',
        component: () => import('./../../../views/products/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Product', permission: 'edit_products' },
        name: 'EditProduct',
        component: () => import('./../../../views/products/edit.vue')
      },
    ]
  },
  {
    path: '/samples',
    meta: { label: 'Samples' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'samples',
        meta: { permission: 'show_all_samples' },
        component: () => import('./../../../views/samples/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Sample Create', permission: 'create_samples' },
        name: 'CreateSample',
        component: () => import('./../../../views/samples/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Sample Details', permission: 'show_single_products' },
        name: 'Sample',
        component: () => import('./../../../views/samples/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Sample', permission: 'edit_products' },
        name: 'EditSample',
        component: () => import('./../../../views/samples/edit.vue')
      },

    ]
  },
]
