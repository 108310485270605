export const capitalize = value => {
  const params = value.split(" ");
  let capitalized = "";
  params.forEach(([first, ...rest]) => {
    if (first != undefined)
      capitalized = capitalized.concat(
        first.toUpperCase() + rest.join("") + " "
      );
  });
  return capitalized;
};
export const abbreviate = value => {
  const params = value.split(" ");
  let abbreviated = "";
  params.forEach(([first]) => {
    if (first != undefined && abbreviated.length < 2)
      abbreviated = abbreviated.concat(first);
  });
  return abbreviated;
};

export const slugRemove = value => {
  const params = value.split("_");
  let capitalized = "";
  params.forEach(([first, ...rest]) => {
    capitalized = capitalized.concat(first + rest.join("") + " ");
  });
  return capitalized;
};

export const shortFormat = value => {
  if (!value) return "";
  value = value.toString();
  return value.slice(0, 3);
};

export const shortName = value => {
  if (!value) return "";
  let fullNameArray = value.split(" ");
  let length = fullNameArray.length;
  if (length >= 2) {
    return `${fullNameArray[0]} ${fullNameArray[length - 1]}`;
  }

  return fullNameArray[0];
};

export const percentage = value => {
  if (!value) return "0.0%";
  value = value.toString();
  return value.slice(0, 4) + "%";
};

export const color = value => {
  if (!value) return "danger";
  if (value <= 30) return "danger";
  if (value <= 50) return "warning";
  if (value < 90) return "primary";
  if (value >= 90) return "success";
};

export const fileNameFromPath = value => {
  let pathArray = value.split("/");

  return pathArray[pathArray.length - 1].split(".").shift();
};

export const fileExtension = value => {
  let pathArray = value.split(".");
  return pathArray.pop();
};

export default {
  capitalize,
  slugRemove,
  shortFormat,
  percentage,
  color,
  abbreviate,
  shortName,
  fileNameFromPath,
  fileExtension
};
