import VueGoogleMaps from '../services/GoogleMaps';

export default {
  async install(Vue) {
    Vue.use(VueGoogleMaps, {
      load: {
        key: window.mapKey,
        libraries: ['places', 'visualization'],
      },
      installComponents: true,
    })
  }
}

