import PlanSettingsRoutes from './visits/plan.settings.routes';
import ActualSettingsRoutes from './visits/actual.settings.routes';
import OtherSettingsRoutes from './visits/other.settings.routes';


export default [
    ...PlanSettingsRoutes,
    ...ActualSettingsRoutes,
    ...OtherSettingsRoutes,

]
