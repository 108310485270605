export default [
  {
    path: 'actual_visits',
    meta: { label: 'Actual Visits' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'actual visits',
        meta: {permission: 'show_all_actual_visits'},
        component: () => import('./../../views/actual_visits/ActualVisits'),
      },
      {
        path: 'create',
        meta: { label: 'Create Actual Visit',permission:'create_actual_visits' },
        name: 'CreateActualVisit',
        component: () => import('./../../views/actual_visits/CreateActualVisit'),
      },
      {
        path: 'create_plan_actual_visit/:id',
        meta: { label: 'Create Plan Actual Visit',permission:'create_plan_actual_visit' },
        name: 'CreatePlanActualVisit',
        component: () => import('./../../views/actual_visits/CreateActualVisit.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Show Actual Visit',permission:'show_single_actual_visits' },
        name: 'ActualVisit',
        component: () => import('./../../views/actual_visits/ActualVisit'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Actual Visit',permission:'edit_actual_visits' },
        name: 'EditActualVisit',
        component: () => import('./../../views/actual_visits/EditActualVisit'),
      },
    ]
  },
  {
    path: 'owactualvisits',
    meta: { label: 'OwActualVisits'},
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name:'ow actual visits',
        meta: {permission: 'show_all_ow_actual_visits'},
        component: () => import('./../../views/owactualvisits/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Office Work Actual Visit Create',permission:'create_ow_actual_visits'},
        name: 'CreateOwActualVisits',
        component: () => import('./../../views/owactualvisits/create.vue'),
      },
      {
        path: 'create_ow_actual_visit/:id',
        meta: { label: 'Create OW Actual Visit',permission:'create_ow_actual_visits' },
        name: 'CreateOWActualVisit',
        component: () => import('./../../views/actual_visits/CreateOWActualVisit.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Office Work Actual Visit Details',permission:'show_single_ow_actual_visits'},
        name: 'OwActualVisit',
        component: () => import('./../../views/owactualvisits/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Office Work Actual Visit',permission:'edit_ow_actual_visits' },
        name: 'EditOwActualVisit',
        component: () => import('./../../views/owactualvisits/edit')
      },
    ]
  },
  {
    path: '/actualdoublefeedbacks',
    meta: { label: 'Actual Double Feedback'},
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: "",
        name: "Actual Double Feedback",
        meta: { permission: "show_all_actual_double_feedbacks" },
        component: () => import('./../../views/actualDoubleFeedback/index.vue'),
      },
    ]
  },
  {
    path: "/actualapprovals",
    meta: { label: "Actual Approvals" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "actual approvals",
        meta: { permission: "show_all_actual_approvals" },
        component: () => import("./../../views/actualapproval/ActualApprovals")
      }
    ]
  },
]
