export default [{
    path: '/user-profile',
    meta: { label: 'User Profile ' },
    component: {
        render(c) { return c('router-view') }
    },
    children: [{
        path: '',
        name: 'profile',
        meta:{ permission:'show_single_user_profile' },
        component: () =>
            import ('../../../views/Profile copy.vue'),
    }, ]
}]
