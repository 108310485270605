export default [
  {
    path: '/dashboard-settings',
    meta: { label: 'Widgets General Setting' },
    component: {
        render(c) { return c('router-view') }
    },
    children: [{
            path: '',
            name: 'DashboardSettings',
            meta:{
                permission: 'show_all_widget_settings'
            },
            component: () =>
                import ('../../views/dashboardsettings/index.vue'),
        },
        {
            path: ':id/edit',
            meta: { 
                label: 'Edit Dashboard Widget Settings',
                permission:'edit_widget_settings' 
            },
            name: 'EditWidgetSettings',
            component: () =>
            import ('../../views/dashboardsettings/edit.vue'),
        },
    ]
},
  {
    path: '/widget-settings',
    meta: { label: 'Widgets Setting',permission:'show_settings_dashboard_widgets' },
    name: 'WidgetSettings',
    component: () => import('../../views/widgetsettings/index.vue')
  }
]
