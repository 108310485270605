export default [
  {
    path: '/live',
    meta: { label: 'Live' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'Live',
        meta: { permission: 'go_live' },
        component: () => import('./../../views/communication/live/index.vue'),
      },
      {
        path:'group/:url',
        name:'Group',
        meta: { permission: 'go_group'},
        component: () => import('../../views/communication/group/index.vue')
      }
    ]
  },
]
