export default [{
    path: 'changepassword',
    meta: { label: 'Change Password'},
    component: {
        render(c) { return c('router-view') }
    },
    children: [{
        path: '',
        name: 'changePassword',
        meta:{permission:'change_password' },
        component: () =>import ('../../../views/ChangePassword.vue'),
    }, ]
}]
