import getData from "./GetData.js";
import deleteData from "./DeleteData.js";
import importFile from "./ImportFile.js";
import exportFile from "./ExportFile.js";
import sendMail from "./SendMail.js";
import checkPermission from "./CheckPermission.js";
import scrollToTop from "./ScrollToTop.js";
import flash from "./Flash.js";
import validationClass from "./ValidationClass.js";
import deleteModalConfirm from "./DeleteModalConfirm.js";
import datesFunctions from "./DateFunctions.js";
import downloadExcel from "./DownloadExcel.js";
import downloadStyledExcel from "./DownloadStyledExcel.js";
import showErrorMessage from "./ShowErrorMessage.js";
import showExcelValidationFailures from "./ShowExcelValidationFailures.js";
import logo from "./GetLogo.js";
import templateDownload from "./TemplateDownload.js";
import Utilities from "./Utilities.js";
import DownloadFile from "./DownloadFile.js";
// import debounce from "./debounce.js";
export default {
  computed: {
    ...validationClass,
    ...logo
  },
  methods: {
    ...getData,
    ...deleteData,
    ...importFile,
    ...exportFile,
    ...sendMail,
    ...checkPermission,
    ...scrollToTop,
    ...flash,
    ...showErrorMessage,
    ...showExcelValidationFailures,
    ...deleteModalConfirm,
    ...datesFunctions,
    ...downloadExcel,
    ...downloadStyledExcel,
    ...templateDownload,
    ...Utilities,
    ...DownloadFile
    // ...debounce
  }
};
