import Vue from "vue";
import Router from "vue-router";
import {storePromise} from '../store'; // Adjust path as needed
import {login} from "../middlewares";

Vue.use(Router);

import dashboardRoutes from "./dashboard";
import internalMessaging from "./internal-messaging";
import mapRoutes from "./maps";

export default new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({y: 0}),
  routes: configRoutes()
});

function configRoutes() {
  return [
    {
      path: "/dashboard",
      name: "Dashboard",
      redirect: "/"
    },
    {
      path: "/",
      name: "Home",
      redirect: "/dashboards/main",
      // component: () => import("../containers/TheContainer.vue"),
      component: () => import("../containers/TheContainer.vue"),
      meta: {title: "Home"},
      children: [...dashboardRoutes, ...mapRoutes, ...internalMessaging]
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: () => import("../views/pages/Page404.vue")
        },
        {
          path: "500",
          name: "Page500",
          component: () => import("../views/pages/Page500")
        }
      ]
    },
    {
      path: "/",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "login",
          name: "Login",
          meta: {permission: "login"},
          beforeEnter: async (to, from, next) => {
            const store = await storePromise
            if (!store.getters['authentication/isAuthenticated']) {
              next()
            } else {
              next({name: 'Main Dashboard'})
            }
          },
          component: () => import("../views/pages/Login")
        },
        {
          path: "register",
          name: "Register",
          component: () => import("../views/pages/Register")
        }
      ]
    },
    {
      path: "*",
      name: "404",
      component: () => import("../views/pages/Page404.vue")
    }
  ];
}
