export default [
  {
    path: '/general-other-settings',
    meta: { label: 'General Other Settings' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_other_settings' },
        name: 'general-other-settings',
        component: () => import('./../../../views/othersettings/index.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Other Setting Details', permission: 'show_single_other_settings' },
        name: 'Other',
        component: () => import('./../../../views/othersettings/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Other Setting', permission: 'edit_other_settings' },
        name: 'EditOther',
        component: () => import('./../../../views/othersettings/edit.vue')
      },
    ]
  },
  {
    path: '/callrate',
    meta: { label: 'Call Rate' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_call_rates' },
        name: 'call-rates',
        component: () => import('./../../../views/callrates/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Call Rate Create', permission: 'create_call_rates' },
        name: 'CreateCallRate',
        component: () => import('./../../../views/callrates/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Call Rate Details', permission: 'show_single_call_rates' },
        name: 'CallRate',
        component: () => import('./../../../views/callrates/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Call Rate', permission: 'edit_call_rates' },
        name: 'EditCallRate',
        component: () => import('./../../../views/callrates/edit.vue')
      },
    ]
  },

  {
    path: '/classfrequencies',
    meta: { label: 'Class Frequency' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_class_frequencies' },
        name: 'class-frequencies',
        component: () => import('./../../../views/classfrequencies/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Class Frequency Create', permission: 'create_class_frequencies' },
        name: 'CreateClassFrequency',
        component: () => import('./../../../views/classfrequencies/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Class Frequency Details', permission: 'show_single_class_frequencies' },
        name: 'ClassFrequency',
        component: () => import('./../../../views/classfrequencies/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Class Frequency', permission: 'edit_class_frequencies' },
        name: 'EditClassFrequency',
        component: () => import('./../../../views/classfrequencies/edit.vue')
      },
    ]
  },

  {
    path: '/specialityfrequencies',
    meta: { label: 'Speciality Frequency' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_speciality_frequencies' },
        name: 'speciality-frequencies',
        component: () => import('./../../../views/specialityfrequencies/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Speciality Frequency Create', permission: 'create_speciality_frequencies' },
        name: 'CreateSpecialityFrequency',
        component: () => import('./../../../views/specialityfrequencies/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Speciality Frequency Details', permission: 'show_single_speciality_frequencies' },
        name: 'SpecialityFrequency',
        component: () => import('./../../../views/specialityfrequencies/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Speciality Frequency', permission: 'edit_speciality_frequencies' },
        name: 'EditSpecialityFrequency',
        component: () => import('./../../../views/specialityfrequencies/edit.vue')
      },
    ]
  },

  {
    path: '/doctorfrequencies',
    meta: { label: 'Doctor Frequency' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_doctor_frequencies' },
        name: 'doctor-frequencies',
        component: () => import('./../../../views/doctorfrequencies/index'),
      },
      {
        path: 'create',
        meta: { label: 'Doctor Frequency Create', permission: 'create_doctor_frequencies' },
        name: 'CreateDoctorFrequency',
        component: () => import('./../../../views/doctorfrequencies/createV2.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Doctor Frequency Details', permission: 'show_single_doctor_frequencies' },
        name: 'DoctorFrequency',
        component: () => import('./../../../views/doctorfrequencies/show'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Doctor Frequency', permission: 'edit_doctor_frequencies' },
        name: 'EditDoctorFrequency',
        component: () => import('./../../../views/doctorfrequencies/edit')
      },
    ]
  },
  {
    path: '/speciality-class-frequencies',
    meta: { label: 'Speciality Class Frequency' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_speciality_class_frequencies' },
        name: 'speciality-class-frequencies',
        component: () => import('./../../../views/specialityclassfrequencies/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Speciality Class Frequency Create', permission: 'create_speciality_class_frequencies' },
        name: 'create-speciality-class-frequencies',
        component: () => import('./../../../views/specialityclassfrequencies/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Speciality Class Frequency Details', permission: 'show_single_speciality_class_frequencies' },
        name: 'speciality-class-frequency',
        component: () => import('./../../../views/specialityclassfrequencies/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Speciality Frequency', permission: 'edit_speciality_class_frequencies' },
        name: 'edit-speciality-class-frequencies',
        component: () => import('./../../../views/specialityclassfrequencies/edit.vue')
      },
    ]
  },
]
