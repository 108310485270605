export default [

  {
    path: 'productReport',
    meta: { label: 'Product Report', permission: 'show_reports_product' },
    name: 'product-report',
    component: () => import('./../../views/reports/ProductReport'),
  },
  {
    path: 'structure-report',
    meta: { label: 'Structure Report', permission: 'show_reports_structure' },
    name: 'structure-report',
    component: () => import('./../../views/reports/StructureReport'),
  },
  {
    path: 'logActivityReport',
    meta: { label: 'Log Activity Report', permission: 'show_reports_log_activity' },
    name: 'LogActivity-report',
    component: () => import('./../../views/reports/LogActivityReport'),
  },

  {
    path: 'list-report',
    meta: { label: 'List Report', permission: 'show_reports_list_report' },
    name: 'list-report',
    component: () => import('./../../views/reports/ListReport'),
  },
  {
    path: 'list-statistics',
    meta: { label: 'List Statistics Report', permission: 'show_reports_list_statistics' },
    name: 'list-statistics-report',
    component: () => import('./../../views/reports/ListStatisticsReport'),
  },
  {
    path: 'list-per-speciality',
    meta: { label: 'List Per Speciality Report', permission: 'show_reports_list_per_speciality' },
    name: 'list-per-speciality-report',
    component: () => import('./../../views/reports/ListPerSpecialityReport'),
  },
  {
    path: 'employee-tracking',
    meta: { label: 'Employee Tracking Report', permission: 'show_reports_employee-tracking' },
    name: 'employee-tracking-report',
    component: () => import('./../../views/reports/EmployeeTrackingReport'),
  },
  {
    path: 'employee-performance',
    meta: { label: 'Employee Performance Report', permission: 'show_reports_employee_performance' },
    name: 'employee-performance-report',
    component: () => import('./../../views/reports/EmployeePerformanceReport.vue'),
  },
  {
    path: 'employee-kpis',
    meta: { label: 'Employee Kpis Report', permission: 'show_reports_employee_kpis' },
    name: 'employee-kpis-report',
    component: () => import('./../../views/reports/KpisReport.vue'),
  },
  {
    path: 'working-hours',
    meta: { label: 'Working Hours Report', permission: 'show_reports_working_hours' },
    name: 'working-hours-report',
    component: () => import('./../../views/reports/WorkingHoursReport.vue'),
  },

  {
    path: 'kpis-policy',
    meta: { label: 'Employee Kpis Policy Report', permission: 'show_reports_kpis_policy' },
    name: 'kpis-policy-report',
    component: () => import('./../../views/reports/KpisPolicyReport.vue'),
  },
  {
    path: 'doctor-profiling-report',
    meta: { label: 'Doctor Profiling Report', permission: 'show_reports_doctor_profiling' },
    name: 'doctor-profiling-report',
    component: () => import('./../../views/reports/DoctorProfilingReport'),
  },
  {
    path: 'linked-pharmacies-report',
    meta: { label: ' Linked Pharmacies Statistics Report', permission: 'show_reports_linked_pharmacies_statistics' },
    name: 'linked-pharmacies-statistics-report',
    component: () => import('./../../views/reports/LinkedPharmaciesReport'),
  },
  {
    path: 'linked-pharmacies-details-report',
    meta: { label: ' Linked Pharmacies Details Report', permission: 'show_reports_linked_pharmacies_details' },
    name: 'linked-pharmacies-details-report',
    component: () => import('./../../views/reports/LinkedPharmaciesDetailsReport'),
  },
  {
    path: 'notifications-report',
    meta: { label: ' Notification Report', permission: 'show_reports_notifications' },
    name: 'notifications-report',
    component: () => import('./../../views/reports/NotificationsReport'),
  },

]