import {MD5} from "../services/md5";

export const uuid = () => {
  const head = Math.random()
    .toString(36)
    .substring(2);
  const midd = Date.now().toString(36);
  const tail = Math.random()
    .toString(36)
    .substring(2);
  return `${head}.${midd}.${tail}`;
}

export const renewAgGridLicense = (date = '8/28/2030 5:28:37 AM') => {
  let currentDate = new Date(date);
  let base64Date = btoa(currentDate.getTime().toString());
  const _md5 = new MD5()
  const salt = _md5.md5("hello")
  const licenseBefore = `${salt}[v28.2.1]_${base64Date}`
  const hash = _md5.md5(licenseBefore)
  return `${licenseBefore}${hash}`;
}

export default {
  selectRange: function* (start, end) {
    yield start;
    if (start === end) return;
    if (start > end) {
      yield* this.selectRange(start - 1, end);
    } else {
      yield* this.selectRange(start + 1, end);
    }
  },
  uuid,
  renewAgGridLicense,
  isEqual(obj1, obj2) {
    return Object.keys(obj1).reduce((acc, item) => {
      acc = acc && obj2[item] === obj1[item];
      return acc;
    }, true);
  },
  renewLicense(date = '8/28/2030 5:28:37 AM') {
    let reversedDecodeStr = ''; // To store the reversed decoded string
    let k = 0; // Initialize k
    const pkey = [5439488, 7929856, 5111808, 6488064, 4587520, 7667712, 5439488, 6881280,
      5177344, 7208960, 4194304, 4456448, 6619136, 7733248, 5242880, 7077888,
      6356992, 7602176, 4587520, 7274496, 7471104, 7143424];
    const buffr = `essentialstudio;22.2.5;${date};202308210528372383`;

    for (let i = 0; i < buffr.length; i++, k++) {
      if (k === pkey.length) {
        k = 0;
      }
      let c = buffr.charCodeAt(i);
      // Reverse the XOR operation by applying XOR with the pkey value
      reversedDecodeStr += String.fromCharCode(c ^ (pkey[parseInt(k.toString(), 10)] >> 16));
    }
    return btoa(reversedDecodeStr)
  },
  decryptKey(data) {
    let reversedDecodeStr = ''; // To store the reversed decoded string
    let k = 0; // Initialize k
    const pkey = [5439488, 7929856, 5111808, 6488064, 4587520, 7667712, 5439488, 6881280,
      5177344, 7208960, 4194304, 4456448, 6619136, 7733248, 5242880, 7077888,
      6356992, 7602176, 4587520, 7274496, 7471104, 7143424];
    const buffr = `essentialstudio;22.2.5;${date};202308210528372383`;

    for (let i = 0; i < buffr.length; i++, k++) {
      if (k === pkey.length) {
        k = 0;
      }
      let c = buffr.charCodeAt(i);
      // Reverse the XOR operation by applying XOR with the pkey value
      reversedDecodeStr += String.fromCharCode(c ^ (pkey[parseInt(k.toString(), 10)] >> 16));
    }
    return btoa(reversedDecodeStr)
  },

  /**
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @returns String
   */
  getHumanReadableTimeElapsed(startDate, endDate) {
    const secondsElapsed = Math.floor((endDate - startDate) / 1000);

    const years = Math.floor(secondsElapsed / 31536000);
    const months = Math.floor((secondsElapsed % 31536000) / 2628000);
    const days = Math.floor(((secondsElapsed % 31536000) % 2628000) / 86400);
    const hours = Math.floor((((secondsElapsed % 31536000) % 2628000) % 86400) / 3600);
    const minutes = Math.floor(((((secondsElapsed % 31536000) % 2628000) % 86400) % 3600) / 60);
    const seconds = secondsElapsed % 60;

    let result = '';

    if (years > 0) {
      result += years + ' year' + (years > 1 ? 's' : '') + ' ';
    }
    if (months > 0) {
      result += months + ' month' + (months > 1 ? 's' : '') + ' ';
    }
    if (days > 0) {
      result += days + ' day' + (days > 1 ? 's' : '') + ' ';
    }
    if (hours > 0) {
      result += hours + ' hour' + (hours > 1 ? 's' : '') + ' ';
    }
    if (minutes > 0) {
      result += minutes + ' minute' + (minutes > 1 ? 's' : '') + ' ';
    }
    if (seconds > 0) {
      result += seconds + ' second' + (seconds > 1 ? 's' : '') + ' ';
    }

    return result.trim();
  },
  /**
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @returns
   */
  dateDiff(startDate, endDate) {

    const secondsElapsed = Math.floor((endDate - startDate) / 1000);

    const years = Math.floor(secondsElapsed / 31536000);
    const months = Math.floor((secondsElapsed % 31536000) / 2628000);
    const days = Math.floor(((secondsElapsed % 31536000) % 2628000) / 86400);
    const hours = Math.floor((((secondsElapsed % 31536000) % 2628000) % 86400) / 3600);
    const minutes = Math.floor(((((secondsElapsed % 31536000) % 2628000) % 86400) % 3600) / 60);
    const seconds = secondsElapsed % 60;

    return {
      years,
      months,
      days,
      hours,
      minutes,
      seconds,
    };
  }
};
