export default [{
    path: 'changeprofilepic',
    meta: { label: 'Change Profile Picture' },
    component: {
        render(c) { return c('router-view') }
    },
    children: [{
        path: '',
        name: 'changeProfilePic',
        meta:{ permission:'change_profile_pic' },
        component: () =>
            import ('../../../views/ChangeProfilePic.vue'),
    }, ]
}]
