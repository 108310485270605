import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {PieChart, BarChart, RadarChart, LineChart, ScatterChart,GaugeChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  PolarComponent,
  ToolboxComponent,
  DatasetComponent,
  VisualMapComponent,
  TimelineComponent,
  TransformComponent
} from "echarts/components";

use([
  ScatterChart,
  CanvasRenderer,
  PieChart,
  BarChart,
  RadarChart,
  LineChart,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  PolarComponent,
  ToolboxComponent,
  DatasetComponent,
  VisualMapComponent,
  TimelineComponent,
  TransformComponent
]);
