export default {
    showExcelValidationFailures(error) {
        const failures = error.response.data.failures;

        let messages = [...failures.reduce((r, e) => {
            let k = `${e.attribute}|${e.errors}`;
            if (!r.has(k)) r.set(k, {...e, count: 1 })
            else r.get(k).count++
                return r;
        }, new Map).values()]

        let filteredMessages = []
        messages.forEach((message) => {
            const attribute = message.attribute;
            const rowNumber = message.row;
            const count = message.count;
            const errors = message.errors;
            filteredMessages.push(`${attribute}[${rowNumber}]=${message.value[attribute]} : ${errors} count: ${count}`);
        });
        this.flash(filteredMessages, "error");
        return failures.map((failure) => {
            return {...failure.value, attribute: failure.errors }
        });
    }
}