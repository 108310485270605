export default [{
    path: 'widgetSettingView',
    meta: { label: 'Widgets' },
    component: {
        render(c) { return c('router-view') }
    },
    children: [{
        path: '',
        // component: () =>
        //     import ('../../../views/widgetsettings/CreateWidgetSetting.vue'),
    }, ]
}]
