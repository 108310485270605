export default[
  {
    path: 'base',
    redirect: '/base/cards',
    name: 'Base',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'breadcrumb',
        name: 'Breadcrumb',
        component: () => import('./../../../views/base/Breadcrumbs')
      },
      {
        path: 'cards',
        name: 'Cards',
        component: () => /* webpackChunkName: Cards */ import('./../../../views/base/Cards')
      },
      {
        path: 'carousel',
        name: 'Carousel',
        component: () => import('./../../../views/base/Carousels')
      },
      {
        path: 'collapse',
        name: 'Collapse',
        component: () => import('./../../../views/base/Collapses')
      },
      {
        path: 'jumbotron',
        name: 'Jumbotron',
        component: () => import('./../../../views/base/Jumbotrons')
      },
      {
        path: 'list-group',
        name: 'List Group',
        component: () => import('./../../../views/base/ListGroups')
      },
      {
        path: 'navs',
        name: 'Navs',
        component: () => import('./../../../views/base/Navs')
      },
      {
        path: 'navbars',
        name: 'Navbars',
        component: () => import('./../../../views/base/Navbars')
      },
      {
        path: 'pagination',
        name: 'Pagination',
        component: () => import('./../../../views/base/Paginations')
      },
      {
        path: 'popovers',
        name: 'Popovers',
        component: () => import('./../../../views/base/Popovers')
      },
      {
        path: 'progress',
        name: 'Progress',
        component: () => import('./../../../views/base/ProgressBars')
      },
      {
        path: 'switches',
        name: 'Switches',
        component: () => import('./../../../views/base/Switches')
      },
      {
        path: 'tabs',
        name: 'Tabs',
        component: () => import('./../../../views/base/Tabs')
      },
      {
        path: 'tooltips',
        name: 'Tooltips',
        component: () => import('./../../../views/base/Tooltips')
      }
    ]
  },
  {
    path: 'tables',
    redirect: '/tables/tables',
    name: 'Tables',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'tables',
        name: 'Basic tables',
        component: () => import('./../../../views/tables/Tables')
      },
      {
        path: 'advanced-tables',
        name: 'Advanced tables',
        component: () => import('./../../../views/tables/AdvancedTables')
      },
      {
        path: 'lazy-loading-tables',
        name: 'Lazy loading tables',
        component: () => import('./../../../views/tables/LazyLoadingTables')
      },
      {
        path: 'lazy-loading-tables-scroll',
        name: 'Lazy loading tables scroll',
        component: () => import('./../../../views/tables/LazyLoadingTablesScroll')
      }
    ]
  },
  {
    path: 'menu',
    meta: { label: 'Menu' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        component: () => import('./../../../views/menu/MenuIndex'),
      },
      {
        path: 'create',
        meta: { label: 'Create Menu' },
        name: 'CreateMenu',
        component: () => import('./../../../views/menu/CreateMenu')
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Menu' },
        name: 'EditMenu',
        component: () => import('./../../../views/menu/EditMenu')
      },
      {
        path: ':id/delete',
        meta: { label: 'Delete Menu' },
        name: 'DeleteMenu',
        component: () => import('./../../../views/menu/DeleteMenu')
      },
    ]
  },
  {
    path: 'menuelement',
    meta: { label: 'MenuElement' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: ':menu/menuelement',
        component: () => import('./../../../views/menuElements/ElementsIndex'),
      },
      {
        path: ':menu/menuelement/create',
        meta: { label: 'Create Menu Element' },
        name: 'Create Menu Element',
        component: () => import('./../../../views/menuElements/CreateMenuElement')
      },
      {
        path: ':menu/menuelement/:id',
        meta: { label: 'Menu Element Details' },
        name: 'Menu Element',
        component: () => import('./../../../views/menuElements/ShowMenuElement'),
      },
      {
        path: ':menu/menuelement/:id/edit',
        meta: { label: 'Edit Menu Element' },
        name: 'Edit Menu Element',
        component: () => import('./../../../views/menuElements/EditMenuElement')
      },
      {
        path: ':menu/menuelement/:id/delete',
        meta: { label: 'Delete Menu Element' },
        name: 'Delete Menu Element',
        component: () => import('./../../../views/menuElements/DeleteMenuElement')
      },
    ]
  },
  {
    path: 'media',
    name: 'Media',
    component: () => import('./../../../views/media/Media')
  },
  {
    path: 'colors',
    name: 'Colors',
    component: () => import('./../../../views/theme/Colors')
  },
  {
    path: 'typography',
    name: 'Typography',
    component: () => import('./../../../views/theme/Typography')
  },
  {
    path: 'charts',
    name: 'Charts',
    component: () => import('./../../../views/charts/Charts')
  },
]
