import '../../../coreui/src/main.js';

import { registerSW } from 'virtual:pwa-register'

const updateSW = registerSW({
    onNeedRefresh() {
        // Show a prompt to user
        if (confirm('New content available. Reload?')) {
            updateSW()
        }
    },
    onOfflineReady() {
        console.log('App ready to work offline')
    },
    immediate: true
});


// if (import.meta.NODE_ENV === 'd') {
//     Log cache storage details
//     navigator.serviceWorker.ready.then(registration => {
//         caches.keys().then(cacheNames => {
//             console.log('Cache names:', cacheNames)
//         })
//     })
// }
