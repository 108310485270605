import LinesSettingRoutes from './structure/lines.routes';
import ProudctSettingRoutes from './structure/product.routes';
import DistributerSettingRoutes from './structure/distributer.routes';
import AccountSettingRoutes from './structure/account.routes';

export default [
    ...LinesSettingRoutes,
    ...ProudctSettingRoutes,
    ...DistributerSettingRoutes,
    ...AccountSettingRoutes,
];
