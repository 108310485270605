export default [
  {
    path: "quiz",
    meta: { label: "Quiz" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "quiz",
        meta: { permission: "show_all_quiz" },
        component: () => import("./../../views/quizsettings/quizzes/index.vue")
      },
      {
        path: 'create',
        meta: { label: 'Quiz Create', permission: 'create_quiz' },
        name: 'CreateQuiz',
        component: () => import('./../../views/quizsettings/quizzes/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Show Quiz', permission: 'show_quiz' },
        name: 'ShowQuiz',
        component: () => import('./../../views/quizsettings/quizzes/show.vue'),
      },
      {
        path: ':id/user/:user_id',
        meta: { label: 'Show Quiz Answers', permission: 'show_quiz' },
        name: 'ShowQuizAnswers',
        component: () => import('./../../views/quizsettings/quizzes/showAnswers.vue'),
      },
    ]
  }
];
