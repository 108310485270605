export default [
  {
    path: '/countries',
    meta: { label: 'Countries' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_countries' },
        name: 'countries',
        component: () => import('./../../../views/countries/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Country Create', permission: 'create_countries' },
        name: 'CreateCountry',
        component: () => import('./../../../views/countries/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Country Details', permission: 'show_single_countries' },
        name: 'Country',
        component: () => import('./../../../views/countries/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Country', permission: 'edit_countries' },
        name: 'EditCountry',
        component: () => import('./../../../views/countries/edit.vue')
      },
    ]
  },
  {
    path: '/currencies',
    meta: { label: 'Currencies' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'currencies',
        meta: { permission: 'show_all_currencies' },
        component: () => import('./../../../views/currencies/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Currency Create', permission: 'create_currencies' },
        name: 'CreateCurrency',
        component: () => import('./../../../views/currencies/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Currency Details', permission: 'show_single_currencies' },
        name: 'Currency',
        component: () => import('./../../../views/currencies/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Currency', permission: 'edit_currencies' },
        name: 'EditCurrency',
        component: () => import('./../../../views/currencies/edit.vue')
      },
    ]
  },
  {
    path: '/divisiontypes',
    meta: { label: 'Division Types' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'division-types',
        meta: { permission: 'show_all_divisiontypes' },
        component: () => import('./../../../views/divisiontypes/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Division Type Create', permission: 'create_divisiontypes' },
        name: 'CreateDivisionType',
        component: () => import('./../../../views/divisiontypes/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Division Type Details', permission: 'show_single_divisiontypes' },
        name: 'DivisionType',
        component: () => import('./../../../views/divisiontypes/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Division Type', permission: 'edit_divisiontypes' },
        name: 'EditDivisionType',
        component: () => import('./../../../views/divisiontypes/edit.vue')
      },
    ]
  },
  {
    path: '/lines',
    meta: { label: 'Lines' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'lines',
        meta: { permission: 'show_all_lines' },
        component: () => import('./../../../views/lines/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Line Create', permission: 'create_lines' },
        name: 'CreateLine',
        component: () => import('./../../../views/lines/create copy.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Line Details', permission: 'show_single_lines' },
        name: 'LineView',
        component: () => import('./../../../views/lines/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Line', permission: 'edit_lines' },
        name: 'EditLine',
        component: () => import('./../../../views/lines/edit copy.vue')
      },
    ]
  },

  {
    path: '/giveaways',
    meta: { label: 'Giveaways' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'giveaways',
        meta: { ermission: 'show_all_line_giveaways' },
        component: () => import('./../../../views/giveaways/index.vue'),
      },
      {
        path: 'samples',
        name: 'giveawaySample',
        component: () => import('./../../../views/giveaways/sample.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Giveaway Create', permission: 'create_line_giveaways' },
        name: 'CreateGiveaway',
        component: () => import('./../../../views/giveaways/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Giveaway Details', permission: 'show_single_line_giveaways' },
        name: 'Giveaway',
        component: () => import('./../../../views/giveaways/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Giveaway', permission: 'edit_line_giveaways' },
        name: 'EditGiveaway',
        component: () => import('./../../../views/giveaways/edit.vue')
      },
    ]
  },
]
