import GeneralReportsRoutes from "./generalReports.routes";
import SalesReportsRoutes from "./salesReports.routes";
import VisitsReportsRoutes from "./visitsReports.routes";
import GpsReportsRoutes from "./gpsReports.routes";
import CoachingReportsRoutes from "./coachingReports.routes";
import MetabaseReports from "./metabaseReports.routes";
import RequestsReports from "./requestsReports.routes";

import CustomReports from "./customReports.routes";
export default [
  {
    path: "/reports",
    meta: { label: "Reports" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        meta: { permission: "show_all_reports" },
        component: () => import("../../views/reports/Reports.vue")
      },
      ...GeneralReportsRoutes,
      ...SalesReportsRoutes,
      ...VisitsReportsRoutes,
      ...GpsReportsRoutes,
      ...CoachingReportsRoutes,
      ...MetabaseReports,
      ...RequestsReports,
      ...CustomReports
    ]
  }
];
