export default {
    templateDownload(filename) {
        axios.get(`/api/download/template/${filename}`, { responseType: "blob" })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data], {
                    type: "application/octet-stream"
                }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link)
            })
            .catch(error => this.showErrorMessage(error))
    }
}