export default[
  {
    path: 'forms',
    redirect: '/forms/basic-forms',
    name: 'Forms',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'basic-forms',
        name: 'Basic Forms',
        component: () => import('./../../../views/forms/Forms')
      },
      {
        path: 'advanced-forms',
        name: 'Advanced Forms',
        component: () => import('./../../../views/forms/AdvancedForms')
      },
      {
        path: 'validation-forms',
        name: 'Form Validation',
        component: () => import('./../../../views/forms/ValidationForms')
      }
    ]
  },
]
