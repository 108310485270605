export default {
    logo() {
        const name = location.hostname.split('.').shift()
        const excludedNames = ['www', 'localhost']
        if (excludedNames.find(value => value == name)) {
            return import.meta.env.ProjectName || 'Gemstone'
        }

        return name
    }
}