export default [
  {
    path: "quiz-categories",
    meta: { label: "Quiz Categories" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "quiz-categories",
        meta: {
          label: "Categories",
          permission: "show_all_quiz_categories"
        },
        component: () =>
          import("../../../views/quizsettings/categories/index.vue")
      },
      {
        path: "create",
        name: "CreateQuizCategory",
        meta: {
          label: "Create Category",
          permission: "create_quiz_categories"
        },
        component: () =>
          import("../../../views/quizsettings/categories/create.vue")
      },
      {
        path: ":id",
        name: "ShowQuizCategory",
        meta: {
          label: "Show Category",
          permission: "show_single_quiz_categories"
        },
        component: () =>
          import("../../../views/quizsettings/categories/show.vue")
      },
      {
        path: ":id/edit",
        name: "EditQuizCategory",
        meta: {
          label: "Edit Category",
          permission: "edit_quiz_categories"
        },
        component: () =>
          import("../../../views/quizsettings/categories/edit.vue")
      }
    ]
  },
  {
    path: "quiz-questions",
    meta: { label: "Quiz Questions" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "quiz-questions",
        meta: {
          label: "Questions",
          permission: "show_all_quiz_questions"
        },
        component: () =>
          import("../../../views/quizsettings/questions/index.vue")
      },
      {
        path: "create",
        name: "CreateQuizQuestion",
        meta: {
          label: "Create Question",
          permission: "create_quiz_questions"
        },
        component: () =>
          import("../../../views/quizsettings/questions/create.vue")
      },
      {
        path: ":id",
        name: "ShowQuizQuestion",
        meta: {
          label: "Show Question",
          permission: "show_single_quiz_questions"
        },
        component: () =>
          import("../../../views/quizsettings/questions/show.vue")
      },
      {
        path: ":id/edit",
        name: "EditQuizQuestion",
        meta: {
          label: "Edit Question",
          permission: "edit_quiz_questions"
        },
        component: () =>
          import("../../../views/quizsettings/questions/edit.vue")
      }
    ]
  },

  // {
  //   path: "quiz",
  //   meta: { label: "Quiz" },
  //   component: {
  //     render(c) {
  //       return c("router-view");
  //     }
  //   },
  //   children: [
  //     {
  //       path: "",
  //       name: "quizzes",
  //       meta: {
  //         label: "quizzes",
  //         permission: "show_all_quizzes"
  //       },
  //       component: () =>
  //         import("../../../views/quizsettings/quizzes/index.vue")
  //     },
  //     {
  //       path: "create",
  //       name: "CreateQuiz",
  //       meta: {
  //         label: "Create Quiz",
  //         permission: "create_quiz"
  //       },
  //       component: () =>
  //         import("../../../views/quizsettings/quizzes/create.vue")
      
  //     },
      

  //   ]
  // },
];
