export default [

  {
    path: '/commercial',
    meta: { label: 'Commercial and Branding' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'commercial',
        meta: { permission: 'show_all_commercials' },
        component: () => import('./../../views/commercialrequest/index.vue'),
      },
      {
        path: 'print',
        meta: { label: 'Print All Commercials', permission: '' },
        name: 'PrintCommercials',
        component: () => import('./../../views/commercialrequest/printAll.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Commercial and Branding Create', permission: 'create_commercials' },
        name: 'CreateCommercial',
        component: () => import('./../../views/commercialrequest/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Commercial and Branding Details', permission: 'show_single_commercials' },
        name: 'Commercial',
        component: () => import('./../../views/commercialrequest/show.vue'),
      },
      {
        path: ':id/print',
        meta: { label: 'Commercial and Branding Details', permission: 'show_single_commercials' },
        name: 'PrintCommercial',
        component: () => import('./../../views/commercialrequest/print.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Commercial and Branding', permission: 'edit_commercials' },
        name: 'EditCommercial',
        component: () => import('./../../views/commercialrequest/edit.vue')
      },
    ]
  },
  {
    path: '/commercial-bills',
    meta: { label: 'Commercial Bills' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'bill',
        meta: { permission: 'show_all_bills' },
        component: () => import('./../../views/commercialBills/index.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Bill', permission: 'edit_bills' },
        name: 'EditBill',
        component: () => import('./../../views/commercialBills/edit.vue')
      },
    ]
  },
  {
    path: 'commercial-bills-approvals',
    meta: { label: 'Commercial Bills Approval' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: '',
        meta: { permission: 'show_menu_commercial_bills_approvals' },
        component: () => import('./../../views/commercialbillsapprovals/CommercialBillsApprovals.vue'),
      },
    ]
  },
  {
    path: '/expenses',
    meta: { label: 'Expenses' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'Expenses',
        meta: { permission: 'show_all_expenses' },
        component: () => import('./../../views/expenses/index.vue'),
      },
      {
        path: 'print',
        meta: { label: 'Print All Expenses', permission: '' },
        name: 'PrintExpenses',
        component: () => import('./../../views/expenses/printAll.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Expense Create', permission: 'create_expenses' },
        name: 'CreateExpense',
        component: () => import('./../../views/expenses/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Expense Details', permission: 'show_single_expenses' },
        name: 'Expense',
        component: () => import('./../../views/expenses/show.vue'),
      },
      {
        path: ':id/print',
        meta: { label: 'Expense Details', permission: 'show_single_expense' },
        name: 'PrintExpense',
        component: () => import('./../../views/expenses/print.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Expense', permission: 'edit_expenses' },
        name: 'EditExpense',
        component: () => import('./../../views/expenses/edit.vue')
      },
    ]
  },
  {
    path: '/expense-locations',
    meta: { label: 'Expense Location' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'expense-location',
        meta: { permission: 'show_all_expense_locations' },
        component: () => import('./../../views/expenseLocations/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Expense Location Create', permission: 'create_expense_locations' },
        name: 'CreateExpenseLocation',
        component: () => import('./../../views/expenseLocations/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Expense Location Details', permission: 'show_single_expense_locations' },
        name: 'ExpenseLocation',
        component: () => import('./../../views/expenseLocations/show.vue'),
      },
      // {
      //   path: ':id/edit',
      //   meta: { label: 'Edit Location Expense',permission:'edit_expense_locations' },
      //   name: 'EditExpenseLocation',
      //   component: () => import('./../../views/expenseLocations/edit.vue')
      // },
    ]
  },
  {
    path: 'vacations',
    meta: { label: 'Vacations' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'vacations',
        meta: { permission: 'show_all_vacations' },
        component: () => import('./../../views/vacations/vacations'),
      },
      {
        path: 'create',
        meta: { label: 'vacation Create', permission: 'create_vacations' },
        name: 'CreateVacation',
        component: () => import('./../../views/vacations/CreateVacation'),
      },
      {
        path: ':id',
        meta: { label: 'Vacation Details', permission: 'show_single_vacations' },
        name: 'vacation',
        component: () => import('./../../views/vacations/vacation'),
      },
      {
        path: ':id/print',
        meta: { label: 'Vacation Details', permission: 'show_single_vacations' },
        name: 'vacationArabic',
        component: () => import('./../../views/vacations/showArabic.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Vacation', permission: 'edit_vacations' },
        name: 'EditVacation',
        component: () => import('./../../views/vacations/EditVacation')
      },
    ]
  },
  {
    path: 'personal-request',
    meta: { label: 'Personal Requests' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'personalRequests',
        meta: { permission: 'show_all_personal_requests' },
        component: () => import('./../../views/personalrequests/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Personal Request Create', permission: 'create_personal_requests' },
        name: 'Create-Personal-Request',
        component: () => import('./../../views/personalrequests/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Personal Request Details', permission: 'show_single_personal_requests' },
        name: 'PersonalRequest',
        component: () => import('./../../views/personalrequests/show.vue'),
      },
      // {
      //   path: ':id/edit',
      //   meta: { label: 'Edit Personal Request',permission:'edit_personal_requests' },
      //   name: 'EditPersonalRequest',
      //   component: () => import('./../../views/personalrequests/edit.vue')
      // },
    ]
  },
  {
    path: 'material',
    meta: { label: 'Promotional Material' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'material',
        meta: { permission: 'show_all_material' },
        component: () => import('./../../views/material/index.vue'),
      },
      {
        path: 'stock',
        name: 'material-stock',
        meta: { permission: 'show_all_material_stocks' },
        component: () => import('./../../views/material/stock.vue'),
      },
      {
        path: 'print',
        meta: { label: 'Print All Materials', permission: 'show_single_material' },
        name: 'PrintMaterials',
        component: () => import('./../../views/material/printAll.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Promotional Material Create', permission: 'create_material' },
        name: 'CreatePromotionalMaterial',
        component: () => import('./../../views/material/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Show Promotional Material', permission: 'show_single_material' },
        name: 'ShowPromotionalMaterial',
        component: () => import('./../../views/material/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Promotional Material', permission: 'edit_material' },
        name: 'EditPromotionalMaterial',
        component: () => import('./../../views/material/edit.vue'),
      },
      {
        path: ':id/print',
        meta: { label: 'Promotional Material Details', permission: 'show_single_material' },
        name: 'PrintMaterial',
        component: () => import('./../../views/material/print.vue'),
      },

    ]
  },

  {
    path: 'material-approvals',
    meta: { label: 'Material Approval' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: '',
        meta: { permission: 'show_menu_material_approvals' },
        component: () => import('./../../views/materialapprovals/MaterialApprovals.vue'),
      },
    ]
  },
  {
    path: 'account-request',
    meta: { label: 'Account Request' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'account-request',
        meta: { permission: 'create_account_requests' },
        component: () => import('./../../views/accountRequest/create.vue'),
      },
    ]
  },
  {
    path: 'account-request-approvals',
    meta: { label: 'Account Request Approval' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'account-request-approval',
        meta: { permission: 'create_account_request_approvals' },
        component: () => import('./../../views/accountRequest/approval.vue'),
      },
    ]
  },
  {
    path: 'budget-setup',
    meta: { label: 'Budget Setup' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'budget',
        meta: { permission: 'show_all_budget_setups' },
        component: () => import('./../../views/budget/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Budget Setup Create', permission: 'create_budget_setups' },
        name: 'CreateBudgetSetup',
        component: () => import('./../../views/budget/create.vue'),
      },
      // {
      //   path: ':id',
      //   meta: { label: 'Show Budget Setup', permission: 'show_single_budget_setup' },
      //   name: 'ShowBudgetSetup',
      //   component: () => import('./../../views/material/show.vue'),
      // },

    ],
  },

  
]

