export default [
  {
    path: 'buttons',
    redirect: '/buttons/standard-buttons',
    name: 'Buttons',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'buttons',
        name: 'Standard Buttons',
        component: () => import('./../../../views/buttons/StandardButtons')
      },
      {
        path: 'button-group',
        name: 'Button Group',
        component: () => import('./../../../views/buttons/ButtonGroups')
      },
      {
        path: 'dropdowns',
        name: 'Dropdowns',
        component: () => import('./../../../views/buttons/Dropdowns')
      },
      {
        path: 'brand-buttons',
        name: 'Brand Buttons',
        component: () => import('./../../../views/buttons/BrandButtons')
      }
    ]
  },
]
