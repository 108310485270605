export default {
    /**
     *
     * @param {string} path
     * @param {FormData} formData
     */
    sendMail(path, formData) {
        axios
            .post(path, formData)
            .then((response) => {
                this.sendModal = false;
                this.flash('Email Sent..')
                this.getData();
            })
            .catch((error) => {
                this.showErrorMessage(error)
            });
    },
}