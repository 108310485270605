import "core-js/stable";
import Vue from "vue";

import VueHtmlToPaper from "vue-html-to-paper";
import {CIcon} from './services/icons-vue/src'
// plugins
import vuetify from "./plugins/vuetify"; // path to vuetify export
import "@mdi/font/css/materialdesignicons.css";
import "./plugins/axios";
import "./plugins/echarts";
import {iconsSet as icons} from "./assets/icons/icons.js";
import {storePromise} from "./store/";
import {Datetime} from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

import ECharts, {THEME_KEY} from "vue-echarts";

import VueGoogleMapsPlugin from "./plugins/vue-google-maps";

Vue.use(VueGoogleMapsPlugin);

import CoreuiVue from "@coreui/vue";
import App from "./App";
import router from "./router/index";

import filters from "./filters";
import middlewares from "./middlewares";
import mixin from "./mixins/index.js";
import {Sketch} from "vue-color";
import {registerLicense} from '@syncfusion/ej2-base';
import {LicenseManager} from "ag-grid-enterprise";


LicenseManager.setLicenseKey(mixin.methods.renewAgGridLicense());
registerLicense(mixin.methods.renewLicense())

Vue.component("datetime", Datetime);
Vue.component("c-icon", CIcon);
Vue.component("e-charts", ECharts);
Vue.component("sketch-picker", Sketch);
Vue.config.performance = true;
Vue.use(CoreuiVue);

Vue.use(Datetime);

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    location.origin + '/' + 'printable.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title // override the window title
};

Vue.use(VueHtmlToPaper, options);

Object.keys(filters).forEach(filter => {
  Vue.filter(filter, filters[filter]);
});


Vue.mixin(mixin);

storePromise.then((store)=>{
  Object.keys(middlewares).forEach(name => {
    router.beforeEach(middlewares[name](store));
  });
  new Vue({
    el: "#app",
    store,
    router,
    provide: {
      [THEME_KEY]: 'white'
    },
    vuetify,
    async beforeCreate() {
      await Promise.all([
        this.$store.dispatch("app/loadLocation"),
        this.$store.dispatch("initializeStore"),
        this.$store.dispatch(
          "app/loadOnlineChannel",
          `${import.meta.env.VITE_WEBSOCKET_HOST}/${import.meta.env.VITE_APP_NAME}/online`
        ),
      ]);
    },
    icons,
    template: "<App/>",
    components: {
      App
    }
  });
})

