export default [
  {
    path: "coaching-performance-summary-report",
    meta: { label: "Training & Performance Summary", permission: "show_reports_coaching_performance_summary" },
    name: "coaching-performance-summary-report",
    component: () => import("./../../views/reports/coaching/CoachingPerformanceSummary.vue")
  },
  {
    path: "coaching-statistics-report",
    meta: { label: "Training Statistics", permission: "show_reports_coaching_statistics" },
    name: "coaching-statistics-report",
    component: () => import("./../../views/reports/coaching/CoachingStatistics.vue")
  },
  {
    path: "training-category-report",
    meta: { label: "Question Quiz", permission: "show_reports_question_quiz" },
    name: "training-category-report",
    component: () => import("./../../views/reports/coaching/TrainingCategory.vue")
  },
  {
    path: "quiz-result-summary-report",
    meta: { label: "Quiz Result Summary", permission: "show_reports_quiz_result_summary" },
    name: "quiz-result-summary-report",
    component: () => import("./../../views/reports/coaching/QuizResultSummary.vue")
  },

];
